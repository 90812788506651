import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get } from "../../../utils/api";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ProfileIcon from "../../../layout/assets/images/Navbar/pexels-beardo.png";
import "./index.scss";
import moment, { MomentInput } from "moment";

function CareersDetails() {
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  let { primary_id } = useParams();
  useEffect(() => {
    getData();
  }, [primary_id]);

  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    // career_id: 0,
    first_name: "",
    last_name: "",
    email_id: "",
    phone_number: "",
    resume: "",
    resume_url: "",
    resume_id: "",

    // careers_id: 0,
  });

  const [loadingLogo, setLoadingLogo] = useState(false);

  const [memberData, setMemberData] = useState({});

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    const newFormattedDate = formattedDate.replace(",", "");

    const [month, day, year] = newFormattedDate.split(" ");
    return `${day} ${month} ${year}`;
  };

  const getData = async () => {
    var params = {};
    params = { career_enquiry_id: Number(primary_id) };
    console.log(params);

    await get(`career_enquiry/details`, params)
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
        setMemberData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("data", memberData);

  const capitalize = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    // <Card style={{ marginTop: "10px", marginBottom: "10px" }}>
    //   <CardContent>
    //     <div className="row">
    //       <div className="col-xs-12">
    //         <div className="d-flex justify-content-between align-items-start fla-modal-content">
    //           <div>
    //             <div>
    //               <p className="contact-label">First Name</p>
    //               <TextField
    //                 className="contact-field"
    //                 //   id="outlined-basic"
    //                 type="text"
    //                 name="first_name"
    //                 value={data.first_name}
    //                 // onChange={(e) => inputHandler(e)}
    //                 //   label="Project Title"
    //                 //   variant="outlined"
    //                 placeholder="first_name"
    //                 sx={{
    //                   "& .MuiOutlinedInput-notchedOutline": {
    //                     borderColor: "#7CA5B0",
    //                   },
    //                 }}
    //               />
    //             </div>
    //             <div>
    //               <p className="contact-label">Last Name</p>
    //               <TextField
    //                 className="contact-field"
    //                 //   id="outlined-basic"
    //                 type="text"
    //                 name="last_name"
    //                 value={data.last_name}
    //                 // onChange={(e) => inputHandler(e)}
    //                 //   label="Project Title"
    //                 //   variant="outlined"
    //                 placeholder="Last Name"
    //                 sx={{
    //                   "& .MuiOutlinedInput-notchedOutline": {
    //                     borderColor: "#7CA5B0",
    //                   },
    //                 }}
    //               />
    //             </div>
    //             <div className="mb-4">
    //               <p className="contact-label">Application Type</p>
    //               <TextField
    //                 className="contact-field"
    //                 //   id="outlined-basic"
    //                 type="text"
    //                 name="quantity"
    //                 value={data.quantity}
    //                 // onChange={(e) => inputHandler(e)}
    //                 //   label="Project Title"
    //                 //   variant="outlined"
    //                 placeholder="Title"
    //                 sx={{
    //                   "& .MuiOutlinedInput-notchedOutline": {
    //                     borderColor: "#7CA5B0",
    //                   },
    //                 }}
    //               />
    //             </div>
    //             <div>
    //               <p className="contact-label app-logo-label">Career Image</p>
    //               <div className="d-flex justify-content-between align-items-center app-logo-container">
    //                 {loadingLogo ? (
    //                   <div>
    //                     <CircularProgress />
    //                   </div>
    //                 ) : (
    //                   data.app_logo !== "" && (
    //                     <div>
    //                       <img
    //                         src={data.image_url}
    //                         className="news-image-edit member-image-new mb-4"
    //                       />
    //                     </div>
    //                   )
    //                 )}
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </CardContent>
    // </Card>

    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px" }}
    >
      <div className="row new-client-header">
        <div className="col-xs-12">
          <h5>Career Details</h5>
        </div>
      </div>
      <div style={{ maxWidth: "1152px" }}>
        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>First Name</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{data.first_name}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Last Name</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{data.last_name}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Email ID</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{data.email_id}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Phone Number</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{data.phone_number}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Resume</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{data.resume}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Resume ID</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{data.resume_id}</span>
          </div>
        </div>
      </div>

      <div className="row mt-3 mb-3">
        <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
          <span>Resume URL</span>
        </div>
        <div className="col-xs-12 col-md-8 col-lg-8">
          <span>{data.resume_url}</span>
        </div>
      </div>
    </div>
  );
}

export default CareersDetails;
