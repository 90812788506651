import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Summary from "./Summary";
import AddNews from "./New";
import NewsList from "./List";
import EditNews from "./Edit";
import NewsDetails from "./Details";

function Index() {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <NewsList />
            </>
          }
        />
        <Route
          path="new"
          element={
            <>
              <AddNews />
            </>
          }
        />
        <Route
          path="edit/:primary_id"
          element={
            <>
              <EditNews />
            </>
          }
        />
        <Route
          path="details/:primary_id"
          element={
            <>
              <NewsDetails />
            </>
          }
        />

        <Route index element={<Navigate to="/news/list" />} />
      </Route>
    </Routes>
  );
}

export default Index;
