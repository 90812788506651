import {
  Checkbox,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { get, patch, post } from "../../../../utils/api";
import SnackbarAlert from "../../../common/sanckbar";
import DeleteIcon from "../../../../layout/assets/images/Invoices/delete-icon.svg";

// import "./index.scss";
interface FieldData {
  key?: string;
  type?: string;
  create_status?: boolean;
  edit_status?: boolean;
  filter_status?: boolean;
  column_status?: boolean;
}

function NewFieldSetting() {
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    // getFieldSettingsData();
    getFieldSettingsDetails();
  }, []);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  // const [fieldData, setFieldData] = useState<FieldData>({
  //   key: "",
  //   type: "",
  //   create_status: false,
  //   edit_status: false,
  //   filter_status: false,
  //   column_status: false,
  // });

  let initialData = {
    key: "",
    type: "",
    create_status: false,
    edit_status: false,
    filter_status: false,
    column_status: false,
  };
  const [data, setData] = useState<any>([initialData]);

  const [formData, setFormData] = useState({
    collection_name: "",
    fields: [],
  });

  const handleNewFieldSetting = async (e: any) => {
    e.preventDefault();
    try {
      const response = await post("field_settings", {
        data: formData,
      });
      console.log(response);
      if (response) {
        alert("Field Setting added successfully");
        navigate("/admin/field-settings/list");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFieldSettingsDetails = (filterData = {}) => {
    console.log(filterData);
    
    // var params = {};
    // params = { field_settings_id:Number (id) };
    var params = { field_settings_id: Number(id), data: formData };
    console.log(params);
    get("field_settings", params)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateFieldSettingsDetails = (filterData = {}) => {
    console.log(filterData);

    var params = {};
    params = { field_settings_id: id };

    console.log(params);
    patch("field_settings", params)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const handleForm = (e: any) => {
    console.log(e.target.name);
    console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const handleChange = (e: any) => {
  //   const { name, type } = e.target;
  //   const value = type === "checkbox" ? e.target.checked : e.target.value;
  //   setFieldData({ ...fieldData, [name]: value });
  // };

  function handleChange(e: any, index: number) {
    const { name, type, checked, value } = e.target;
  
    // For checkboxes, get the checked value instead of value
    const newValue = type === "checkbox" ? checked : value;
  
    const newData = [...data];
    newData[index] = {
      ...newData[index],
      [name]: newValue,
    };
    setData(newData);
  }

  const removeLine = (index: number) => {
    data.splice(index, 1);
    setData([...data]);
  };
  // const addNewLine = (e: any) => {
  //   fieldData.push({
  //     // customer_name: "",
  //     key: "",
  //     type: "",
  //     create_status: false,
  //     edit_status: false,
  //     filter_status: false,
  //     column_status: false,
  //   });
  //   setFieldData([...data]);
  // };

  // const removeLine = (index: number) => {
  //   data.splice(index, 1);
  //   setFieldData([...data]);
  // };

  // const addNewLine = () => {
  // formData.fields.push({
  //   key: "",
  //   type: "",
  //   create_status: false,
  //   edit_status: false,
  //   filter_status: false,
  //   column_status: false,
  // });
  //   setFormData({ ...formData });
  //   if (fieldData.key && fieldData.type) {
  //     setFormData((prevFormData: any) => ({
  //       ...prevFormData,
  //       fields: [...prevFormData.fields, fieldData],
  //     }));
  //   }

  //   console.log(formData);
  // };

  const addNewLine = (e: any) => {
    data.push({
      key: "",
      type: "",
      create_status: false,
      edit_status: false,
      filter_status: false,
      column_status: false,
    });
    setData([...data]);
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>New Field Setting</h5>
          </div>
        </div>
        <form onSubmit={handleNewFieldSetting} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="collectionName">Collection Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="collectionName"
                  className="reg-input name-field"
                  name="collection_name"
                  value={formData.collection_name}
                  onChange={handleForm}
                  placeholder="Collection Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>
          <Table className="table table-striped invoices-table">
            <TableHead>
              <TableRow>
                <TableCell>Field Key</TableCell>
                <TableCell>Field Type</TableCell>
                <TableCell>Create Status</TableCell>
                <TableCell>Edit Status</TableCell>
                <TableCell>Column Status</TableCell>
                <TableCell>Filter Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((field: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      required
                      id="fields"
                      size="small"
                      variant="standard"
                      name="key"
                      value={field.key}
                      onChange={(e) => handleChange(e, index)}
                      placeholder="Key"
                    ></TextField>
                  </TableCell>

                  <TableCell>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      name="type"
                      variant="standard"
                      value={field.type}
                      label="Type"
                      placeholder="Type"
                      onChange={(e) => handleChange(e, index)}
                    >
                      <MenuItem value={"String"}>String</MenuItem>
                      <MenuItem value={"Number"}>Number</MenuItem>
                      <MenuItem value={"Date"}>Date</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Checkbox
                      name="create_status"
                      checked={field.create_status}
                      onChange={(e) => handleChange(e, index)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Checkbox
                      name="edit_status"
                      checked={field.edit_status}
                      onChange={(e) => handleChange(e, index)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Checkbox
                      name="column_status"
                      checked={field.column_status}
                      onChange={(e) => handleChange(e, index)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Checkbox
                      name="filter_status"
                      checked={field.filter_status}
                      onChange={(e) => handleChange(e, index)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </TableCell>
                  {data.length > 1 ? (
                    <TableCell>
                      <Button
                        key={index}
                        className="justify-content-start"
                        onClick={() => removeLine(index)}
                      >
                        <img src={DeleteIcon} alt="" />
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                </TableRow>
              ))}
              {/* <TableRow>
                <TableCell>
                  {" "}
                  <TextField
                    required
                    id="fields"
                    size="small"
                    variant="standard"
                    name="key"
                    value={fieldData.key}
                    onChange={handleChange}
                    placeholder="Key"
                  ></TextField>
                </TableCell>
                <TableCell>
                  {" "}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    name="type"
                    variant="standard"
                    value={fieldData.type}
                    label="Type"
                    placeholder="Type"
                    onChange={handleChange}
                  >
                    <MenuItem value={"String"}>String</MenuItem>
                    <MenuItem value={"Number"}>Number</MenuItem>
                    <MenuItem value={"Date"}>Date</MenuItem>
                  </Select>
                </TableCell>
                <TableCell>
                  {" "}
                  <Checkbox
                    name="create_status"
                    checked={fieldData.create_status}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    name="edit_status"
                    checked={fieldData.edit_status}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    name="column_status"
                    checked={fieldData.column_status}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                <TableCell>
                  {" "}
                  <Checkbox
                    name="filter_status"
                    checked={fieldData.filter_status}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
           
              </TableRow> */}
            </TableBody>
          </Table>
          <div className="row add-newline-container">
            <div className="col-xs-12">
              <Button
                variant="contained"
                className="add-new-item"
                onClick={addNewLine}
              >
                <span className="add-new-item-text">+ add new item </span>
              </Button>
            </div>
          </div>

          <div className="d-flex justify-content-center p-4">
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#B66643",
                color: "#FFF",
                textTransform: "none",
                width: "16.3411vw",
                height: "3.3854vw",
              }}
              className="save-btn"
            >
              <span>Save Field</span>
            </Button>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-2 col-lg-3"></div>
            <div className="col-xs-12 col-md-2 col-lg-3"></div>

            <div className="col-xs-12 col-md-2 col-lg-3"></div>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewFieldSetting;
