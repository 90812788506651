import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Card, CardContent } from "@mui/material";
import { get, put } from "../../../utils/api";
import SnackbarAlert from "../../common/sanckbar";

function Editgalleries(props: any) {
  let { primary_id } = useParams();
  useEffect(() => {
    getData();
  }, [primary_id]);

  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    album_name: "",
    // album_name_lng: {
    //   en: "",
    //   pl: "",
    //   ta: "",
    // },
    thumbnail: {
      image_id: "",
      image_url: "",
    },
    album_id: 0,
    images: [],
    videos: [],
    status: "",
    status_id: 0,
  });
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const getData = () => {
    var params = {};
    params = { album_id: Number(primary_id) };
    console.log(params);

    get(`galleries/details`, params)
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(data);
    const newData = {
      album_name: data.album_name,
      // album_name_lng: {
      //   ...data.album_name_lng,
      // },
      // content: data.content,
    };

    try {
      const response = await put(`galleries/${primary_id}`, newData);
      console.log(response);
      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/galleries/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  // const onChangeHandler = async (event: any) => {
  //   console.log("converted------------file", event.target.files[0]);
  //   uploadImage(event.target.files[0]);
  // };
  // const uploadImage = async (imageFile: any) => {
  //   console.log("upload------------file", imageFile);
  //   if (imageFile) {
  //     setImageLoading(true);
  //     const data = new FormData();
  //     const options = {
  //       maxSizeMB: 0.5,
  //       maxWidthOrHeight: 1920,
  //     };
  //     // for (const file of event.target.files) {
  //     let compressedFile = await imageCompression(imageFile, options);
  //     data.append("files", compressedFile, imageFile.name);
  //     // data.append("profile_id", state.customer._id);
  //     // }
  //     // let that = this;
  //     upload("api/s3FileUpload/" + "news" + "/" + imageFile.name, data).then(
  //       (res: any) => {
  //         console.log(res.data.status);
  //         if (res.data.status == "success") {
  //           console.log("success", "called getprofile");
  //           getProfile();
  //         }
  //       }
  //     );
  //   }
  // };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h5>Edit Galleries</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">Album Name</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="album_name"
                      value={data.album_name}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>

                  <div className="d-flex justify-content-end align-items-center news-button-section">
                    <div className="d-flex justify-content-end">
                      <div></div>
                      <Button type="submit" variant="contained">
                        Update
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Editgalleries;
