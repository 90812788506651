// import React from "react";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Button,
// } from "@mui/material";

// // Define prop types using TypeScript interfaces
// interface ConfirmationDialogProps {
//   open: boolean;
//   onClose: () => void;
//   onConfirm: () => void;
// }

// const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
//   open,
//   onClose,
//   onConfirm,
// }) => {
//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>{"Confirm Delete"}</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Are you sure you want to delete this item?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           No
//         </Button>
//         <Button onClick={onConfirm} color="primary" autoFocus>
//           Yes
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default ConfirmationDialog;



// import React from "react";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Button,
// } from "@mui/material";

// // Define prop types using TypeScript interfaces
// interface ConfirmationDialogProps {
//   open: boolean;
//   onClose: () => void;
//   onConfirm: () => void;
// }

// const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
//   open,
//   onClose,
//   onConfirm,
// }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       PaperProps={{
//         sx: {
//           backdropFilter: "blur(5px)", // Apply blur effect directly on the dialog
//           backgroundColor: "rgba(255, 255, 255, 0.8)", // Light background for the dialog content
//         },
//       }}
//       sx={{
//         '& .MuiBackdrop-root': {
//           // Make sure there's no black background
//           backgroundColor: "transparent", // Set backdrop to transparent
//         },
//       }}
//     >
//       <DialogTitle>{"Confirm Delete"}</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           Are you sure you want to delete this item?
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           No
//         </Button>
//         <Button onClick={onConfirm} color="primary" autoFocus>
//           Yes
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default ConfirmationDialog;



import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// Define prop types using TypeScript interfaces
interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backdropFilter: "blur(5px)", // Apply blur effect directly on the dialog
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)", // Add a subtle shadow
    },
      }}
      sx={{
        '& .MuiBackdrop-root': {
          // Make sure there's no black background
          backgroundColor: "transparent", // Set backdrop to transparent
        },
      }}
    >
      <DialogTitle>{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
