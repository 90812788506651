import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import Add from "./New";


// import EditContactUs from "./Edit";

import ContactUsList from "./List";
import ContactUsDetails from "./Details";

function ContactUs() {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <ContactUsList />
            </>
          }
        />
        {/* <Route
          path="new"
          element={
            <>
              <Add />
            </>
          }
        /> */}
        {/* <Route
          path="edit/:primary_id"
          element={
            <>
              <EditContactUs />
            </>
          }
        /> */}
        <Route
          path="details/:primary_id"
          element={
            <>
              <ContactUsDetails />
            </>
          }
        />

        <Route index element={<Navigate to="/general_enquiry/list" />} />
      </Route>
    </Routes>
  );
}

export default ContactUs;
