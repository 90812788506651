import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { awsupload, get, patch, put } from "../../../utils/api";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "./index.scss";
import DummyIcon from "../../../layout/assets/images/DummyImages/pexels-dummy.jpg";
import Tabswitch from "../../../layout/components/Tabswitch/Tabswitch";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import imageCompression from "browser-image-compression";
import AppLogoIcon from "../../../layout/assets/images/Navbar/app_logo_default.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EditCareers() {
  let { primary_id } = useParams();
  useEffect(() => {
    getCareerDetails();
  }, [primary_id]);
  const navigate = useNavigate();
  console.log(primary_id);
  let career_enquiry_id = primary_id;

  const [data, setData] = useState({
    // career_id: 0,
    first_name: "",
    last_name: "",
    email_id: "",
    phone_number: "",
    resume: "",
    resume_url: "",
    resume_id: "",
  });

  const [rowsPerPage, setRowsPerPage] = useState(198);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const [loadingLogo, setLoadingLogo] = useState(false);

  const inputHandler = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(e.target.value);
  };

  const handleSubmit = async () => {
    // const album_id = data.album_id;
    // if (data.images.length > 0) {
    console.log(data);
    const newData = {
      ...data,
    };
    console.log(newData);

    try {
      const response = await put(`career_enquiry/${primary_id}`, newData);
      console.log(response);
      if (response) {
        navigate("/career_enquiry/list");
        // getData();
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const handleLogoUrlChange = (event: any, e: any) => {
    setData((prevData: any) => ({ ...prevData, image_url: e.target.value }));
    setLoadingLogo(false);
    uploadImage(event, e);
  };

  const uploadImage = async (event: any, e: any) => {
    // let file_id: any;
    let folder_name = "careers";
    setLoadingLogo(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload(
      "uploadFile/" +
        folder_name +
        "/" +
        career_enquiry_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          const newData = {
            ...data,
            image_url: res.data.image_url,
            image_id: res.data.image_id,
          };
          setData(newData);
          setLoadingLogo(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  console.log(data);

  const getCareerDetails = () => {
    var params = {};
    params = { career_enquiry_id: Number(primary_id) };
    get("career_enquiry/details", params)
      .then((response: any) => {
        let careerData = response.data;
        console.log(careerData);
        setData(careerData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(data);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px", marginBottom: "50px" }}
    >
      <Card>
        <CardContent>
          <div className="row">
            <div className="col-xs-12">
              {/* <div className="d-flex justify-content-between align-items-center fla-modal-header">
                <p className="mb-0">View application</p>
                <Button onClick={handleModalClose}>close</Button>
              </div> */}
              <div className="d-flex justify-content-between align-items-start fla-modal-content">
                <div>
                  <div className="mb-4">
                    <p className="contact-label">First Name</p>
                    <TextField
                      className="contact-field"
                      //   id="outlined-basic"
                      type="text"
                      name="first_name"
                      value={data.first_name}
                      onChange={(e) => inputHandler(e)}
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Title"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="contact-label">Last Name</p>
                    <TextField
                      className="contact-field"
                      //   id="outlined-basic"
                      type="text"
                      name="last_name"
                      value={data.last_name}
                      onChange={(e) => inputHandler(e)}
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Title"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <p className="contact-label">Email ID</p>
                    <TextField
                      className="contact-field"
                      //   id="outlined-basic"
                      type="text"
                      name="email_id"
                      value={data.email_id}
                      onChange={(e) => inputHandler(e)}
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Email ID"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <p className="contact-label">Phone Number</p>
                    <TextField
                      className="contact-field"
                      //   id="outlined-basic"
                      type="text"
                      name="phone_number"
                      value={data.phone_number}
                      onChange={(e) => inputHandler(e)}
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Phone Number"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#09414E",
                    "&:hover": {
                      backgroundColor: "#09414E",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditCareers;
