// import React, { createContext, useReducer } from "react";
// import { useContext } from "react";
// // import { getAccessToken } from './AuthContext';

// let AppContext = createContext();

// const initialState = {
//   isAuthenticated: false,
//   accessToken:null,
//   currentOrganization:"",
//   user:{
//     first_name:'',
//     last_name:'',
//   }
// }
// // state={count,isAuthenticated}

// let reducer = (state, action) => {
//   switch (action.type) {
//     case "setAuth": {
//       return { ...state, isAuthenticated: action.data }
//     }
//   }
//   return state;
// };

// function AppContextProvider(props) {
//   const fullInitialState = {
//     ...initialState,
//   }

//   let [state, dispatch] = useReducer(reducer, fullInitialState);
//   let value = { state, dispatch };


//   return (
//     <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
//   );
// }
// //  const useAuth = () => {
// //   const context = useContext(AppContext);
// //   if (!context) {
// //     throw new Error("useAuth must be used within an AuthProvider");
// //   }
// //   return context;
// // };


// function getAccessToken() {
//   const { state } = useContext(AppContext);
//   return state.accessToken;
// }

// let AppContextConsumer = AppContext.Consumer;

// export { AppContext, AppContextProvider, AppContextConsumer ,getAccessToken};


import React, { createContext, useReducer, useContext } from "react";

let AppContext = createContext();

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  currentOrganization: "",
  user: {
    first_name: '',
    last_name: '',
  }
}

let reducer = (state, action) => {
  switch (action.type) {
    case "setAuth": {
      return { ...state, isAuthenticated: action.data }
    }
    // Add more cases for other actions if needed
    default:
      return state;
  }
};

function AppContextProvider(props) {
  const fullInitialState = {
    ...initialState,
  }

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

// Custom Hook to get access token
function useAccessToken() {
  const { state } = useContext(AppContext);
  return state.accessToken;
}

let AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer, useAccessToken };
