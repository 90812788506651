import { AddAPhoto } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function PhotoGallery(props: any) {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.setSelectedImageId(null); // Reset the selected image ID
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  return (
    <Card
      className="album_card album_card_photo"
      // style={{ marginLeft: "100px", marginRight: "100px" }}
    >
      <div
        className="row ss gallery-card-container d-flex "
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        <div className="warning-text-container text-end">
          <h5 className="warning-text">
            Please upload images in JPEG, PNG or JPG format only!
          </h5>
        </div>
        {props.data.images.length > 0 ? (
          <>
            {props.data.images.map((imageData: any, index: number) => {
              const handleClick = (
                event: React.MouseEvent<HTMLButtonElement>
              ) => {
                setAnchorEl(event.currentTarget);
                props.setSelectedImageId(imageData.image_id); // Store the selected image ID
              };

              return (
                <div key={index}>
                  <Card
                    className="col-sm-3 gallery-album me-3"
                    id={`long-button-${imageData.image_id}`}
                    style={{ marginRight: "5px" }}
                    sx={{ bgcolor: "#ededf4" }}
                  >
                    <div className="w-50 d-flex justify-content-end mx-auto me-0">
                      <IconButton
                        aria-label="more"
                        id={`long-button-${imageData.image_id}`}
                        aria-controls={
                          open ? `long-menu-${imageData.image_id}` : undefined
                        }
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        size="small"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={`long-menu-${imageData.image_id}`}
                        anchorEl={anchorEl}
                        open={
                          open && props.selectedImageId === imageData.image_id
                        } // Only open the menu if the selected image ID matches
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: 24 * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem
                          id={`long-menu-item-deleteimage${imageData.image_id}`}
                          onClick={() => {
                            props.deleteImage(imageData.image_id);
                            handleClose();
                          }}
                        >
                          Delete
                        </MenuItem>
                        <MenuItem
                          id={`long-menu-item-setthumb${imageData.image_id}`}
                          onClick={() => props.handleSetThumbnail(imageData)}
                        >
                          Set as thumbnail
                        </MenuItem>
                      </Menu>
                    </div>
                    <CardContent>
                      <div
                        style={{
                          height: "150px", // Set the desired height for the cards
                          overflow: "hidden",
                        }}
                        className="d-flex flex-row justify-content-center align-items-center"
                      >
                        <img
                          src={imageData.s3Url}
                          style={{
                            width: "auto",
                            height: "100%",
                          }}
                          alt=""
                        />
                      </div>
                    </CardContent>
                  </Card>
                  <br />
                  <br />
                </div>
              );
            })}
            <div className="add-image-container">
              <Card
                sx={{ bgcolor: "#ededf4", marginTop: "20px" }}
                className="me-3"
              >
                <CardContent>
                  {props.loadingImg ? (
                    <div
                      style={{ height: "200px" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <CircularProgress size={40} />
                    </div>
                  ) : (
                    <div className="w-100 col-sm-2 gallery-album mt-0 p-0">
                      <div className="w-100 add-image">
                        <AddAPhoto
                          className="add_image_icon"
                          color="disabled"
                        ></AddAPhoto>
                        <input
                          type="file"
                          multiple
                          accept="image/png, image/jpeg, image/jpg"
                          name="image_url"
                          onChange={props.onChang3eHandler}
                          className="image_select_input"
                        />
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </>
        ) : (
          <div className="add-image-container">
            <Card
              sx={{ bgcolor: "#ededf4", marginTop: "20px" }}
              className="me-3"
            >
              <CardContent>
                {props.loadingImg ? (
                  <div
                    style={{ height: "200px" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : (
                  <div className="w-100 col-sm-2 gallery-album mt-0 p-0">
                    <div className="w-100 add-image">
                      <AddAPhoto
                        className="add_image_icon"
                        color="disabled"
                      ></AddAPhoto>
                      <input
                        type="file"
                        multiple
                        accept="image/png, image/jpeg, image/jpg"
                        name="image_url"
                        onChange={props.onChang3eHandler}
                        className="image_select_input"
                      />
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      <br></br> <br></br>
    </Card>
  );
}

export default PhotoGallery;
