import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownIcon from "./DropdownIcon";

const dropDownData = [
  {
    key: "Edit",
    label: "Profile",
    icon: <MoreHorizIcon />,
  },
  {
    key: "Archive",
    label: "Settings",
    icon: <MoreHorizIcon />,
  },
  {
    key: "Copy",
    label: "Logout",
    icon: <MoreHorizIcon />,
  },
];

interface Props {
  name?: string;
  imagePath?: string;
  color?: string;
  className?: string;
  buttonClass?: string;
  endIcon?: any;
  spanclass?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  customIcon?: React.AllHTMLAttributes<HTMLDivElement>;
  customText?: string;
  customFunction?: any;
  menuItemClass?: string;
  menuDataItemClass?: string;
  dropDownData?: any;
  customElement?: any;
  handleFieldClick?: any;
  menuClass?: string;
  menuData?: any;
  profileFunction?: any;
}

export default function Dropdown(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={props.className} onClick={props.onClick}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={props.endIcon}
        style={{ backgroundColor: props.color }}
        className={props.buttonClass}
      >
        {props.imagePath && <img src={props.imagePath} alt="" />}
        <span className={props.spanclass}>{props.name}</span>
      </Button>
      <Menu
        disableScrollLock
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={props.menuClass}
      >
        {props.menuData &&
          props.menuData.length > 0 &&
          props.menuData.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                className={props.menuDataItemClass}
                value={item.value ? item.value : ""}
                onClick={(e) => {
                  item.value === "Logout" && props.customFunction();
                  item.value === "Profile" && props.profileFunction();
                  props.handleFieldClick(e);
                  handleClose();
                }}
                disableRipple
              >
                {props.customElement && props.customElement}
                {item.value ? item.value : ""}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
