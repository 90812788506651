import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";

function FYSettingsDetails() {
  useEffect(() => {
    getFYSettingsDetails();
  }, []);
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);

  const [formData, setFormData] = useState({
    fy_year: '',
    fy_code: 0,
    invoice_no:0,
    purchase_invoice_no:0,
    cash_balance: 0,
    bank_balance: 0,
    total_credit: 0,
    total_debt: 0,
    bank_credit: 0,
    audit_status: '',
    other_settings: {}
  });

  const getFYSettingsDetails = () => {
    var params = {};
    params = { fy_settings_id: id };

    get("FYSettings/:id", params)
      .then((data: any) => {
        let fySettingsData = data.data.data;
        console.log(fySettingsData);
        setFormData(fySettingsData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(formData);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px" }}
    >
      <div className="row new-client-header">
        <div className="col-xs-12">
          <h5>FY Settings Details</h5>
        </div>
      </div>
      <div style={{ maxWidth: "1152px" }}>
        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>FY Year</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.fy_year}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>FY Code</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.fy_code}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Invoice No.</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.invoice_no}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Purchase Invoice No.</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.purchase_invoice_no}</span>
          </div>
        </div>
        
        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Cash Balance</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.cash_balance}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Bank Balance</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.bank_balance}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Total Credit</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.total_credit}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Total Debt</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.total_debt}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Bank Credit</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.bank_credit}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Audit Status</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.audit_status}</span>
          </div>
        </div>

        {/* <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Other Settings</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.other_settings}</span>
          </div>
        </div> */}

        
      </div>
    </div>
  );
}

export default FYSettingsDetails;
