import * as React from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { TextField } from "@mui/material";
import "./index.scss";
import FilterChip from "../FiterChip";
import moment from "moment";

export default function BasicMenu(props: any) {
  const [filterText, setFilterText] = React.useState("");

  function checkDateType(filterText: any) {
    const dateFormats = [
      "YYYY-MM-DD",
      "DD-MM-YYYY",
      "MM-DD-YYYY",
      "DD MMM YYYY",
      "MMM DD YYYY",
    ];
    for (let i = 0; i < dateFormats.length; i++) {
      const date = moment(filterText, dateFormats[i], true);
      if (date.isValid()) {
        return "Date";
        // return date.toDate();
      }
    }
    return "String";
  }

  function getType(string: any) {
    // const dateFormats = [
    //   "YYYY-MM-DD",
    //   "DD-MM-YYYY",
    //   "MM-DD-YYYY",
    //   "DD MMM YYYY",
    //   "MMM DD YYYY",
    // ];
    // const datePattern = /(\d{4}-[01]\d-[0-3]\d)/;
    const numberPattern = /(-?\d+\.\d+)|(-?\d+)/;
    if (checkDateType(string) == "Date") {
      return "Date";
    } else if (numberPattern.test(string)) {
      return "Number";
    } else {
      return "String";
    }
  }

  const handleFilter = (filter: any) => {
    props.setFilterData([...props.filterData, filter]);
    setFilterText("");
  };
  const deleteFilter = (index: number) => {
    props.setFilterData(
      props.filterData.filter((el: any, i: Number) => i !== index)
    );
  };

  const anchorEl = document.getElementById("filter-text");

  return (
    <div>
      <TextField
        required
        size="small"
        id="filter-text"
        variant="standard"
        name="filter_text"
        value={filterText}
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
        placeholder={props.placeholder}
        sx={props.sx}
        InputProps={props.InputProps}
      />
      {props.filterData.map((item: any, index: number) => (
        <FilterChip
          title={item.key}
          value={item.value}
          index={index}
          closeChip={deleteFilter}
        ></FilterChip>
      ))}
      {filterText?.length > 0 && (
        <Paper
          sx={{
            width: 320,
            maxWidth: "100%",
            position: "absolute",
            zIndex: "1",
          }}
          className={props.filterData.length > 0 ? "filter-text-options" : ""}
        >
          <MenuList>
            {props.filterOptions
              .filter(
                (e: any) =>
                  e.type == getType(filterText) && e.filter_status == true
              )
              .map((option: any, index: number) => (
                <MenuItem
                  onClick={(e) =>
                    handleFilter({
                      key: option.key,
                      value: filterText,
                      type: option.type,
                    })
                  }
                >
                  <ListItemText className="filter-option-text">
                    {option.key.replace("_", " ")} : {filterText}
                  </ListItemText>
                </MenuItem>
              ))}
          </MenuList>
        </Paper>
      )}
    </div>
  );
}
