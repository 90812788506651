import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { awsupload, get, patch, put } from "../../../utils/api";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "./index.scss";
import DummyIcon from "../../../layout/assets/images/DummyImages/pexels-dummy.jpg";
import Tabswitch from "../../../layout/components/Tabswitch/Tabswitch";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import imageCompression from "browser-image-compression";
import AppLogoIcon from "../../../layout/assets/images/Navbar/app_logo_default.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EditProducts() {
  let { primary_id } = useParams();
  useEffect(() => {
    getProductDetails();
  }, [primary_id]);
  const navigate = useNavigate();
  console.log(primary_id);
  let products_id = primary_id;

  const [data, setData] = useState({
    // product_category_id: 0,
    product_category: "",
    product: "",
    quantity: 0,
    image_url: "",
    image_id: "",
    // products_id: 0,
  });

  const [rowsPerPage, setRowsPerPage] = useState(198);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const [loadingLogo, setLoadingLogo] = useState(false);

  const inputHandler = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(e.target.value);
  };

  const handleSubmit = async () => {
    // const album_id = data.album_id;
    // if (data.images.length > 0) {
    console.log(data);
    const newData = {
      ...data,
    };
    console.log(newData);

    try {
      const response = await put(`product_category/${primary_id}`, newData);
      console.log(response);
      if (response) {
        navigate("/product_category/list");
        // getData();
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const handleLogoUrlChange = (event: any, e: any) => {
    setData((prevData: any) => ({ ...prevData, product_img: e.target.value }));
    setLoadingLogo(false);
    uploadImage(event, e);
  };

  const uploadImage = async (event: any, e: any) => {
    // let file_id: any;
    let folder_name = "product_categories";
    setLoadingLogo(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload(
      "uploadFile/" +
        folder_name +
        "/" +
        products_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          const newData = {
            ...data,
            image_url: res.data.image_url+"?timestamp="+new Date().getTime(),
            image_id: res.data.image_id,
          };
          setData(newData);
          setLoadingLogo(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  console.log(data);

  const getProductDetails = () => {
    var params = {};
    params = { product_category_id: Number(primary_id) };
    get("product_category/details", params)
      .then((response: any) => {
        let productData = response.data;
        console.log(productData);
        setData(productData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(data);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px", marginBottom: "50px" }}
    >
      <Card>
        <CardContent>
          <div className="row">
            <div className="col-xs-12">
              <div className="d-flex justify-content-between align-items-start fla-modal-content">
                <div className="w-100">
                  <p className="contact-label contact-label-product">
                    Product category
                  </p>
                  <TextField
                    className="contact-field w-100"
                    //   id="outlined-basic"
                    type="text"
                    name="product_category"
                    value={data.product_category}
                    onChange={(e) => inputHandler(e)}
                    //   label="Project Title"
                    //   variant="outlined"
                    placeholder="Title"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#7CA5B0",
                      },
                    }}
                  />
                </div>

                <div className="w-50"></div>

                <div className="w-100">
                  <p className="contact-label contact-label-product app-logo-label">
                    Product Image
                  </p>
                  <div className="d-flex flex-column justify-content-between align-items-start app-logo-container">
                    {loadingLogo ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      data.image_url !== "" && (
                        <div>
                          <img
                            src={data.image_url}
                            className="news-image-edit member-image-new product-image-edit mb-4"
                          />
                        </div>
                      )
                    )}
                    <div>
                      <FormControl>
                        <Button
                          variant="contained"
                          size="small"
                          component="label"
                          htmlFor="file-upload-cuisine"
                          className="app-logo-upload"
                          sx={{
                            bgcolor: "#09414E",
                            "&:hover": {
                              backgroundColor: "#09414E",
                            },
                          }}
                        >
                          Upload
                          {/* <span>logo</span> */}
                          <Input
                            // disabled={!data.content || !data.title}
                            id="file-upload-cuisine"
                            type="file"
                            onChange={(event) =>
                              handleLogoUrlChange(event, event)
                            }
                            inputProps={{ accept: "image/*" }}
                            className="image-input me-3"
                          />
                        </Button>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#09414E",
                    "&:hover": {
                      backgroundColor: "#09414E",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default EditProducts;
