import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  awsupload,
  get,
  getProfile,
  patch,
  post,
  put,
  upload,
} from "../../../utils/api";
import imageCompression from "browser-image-compression";
import SnackbarAlert from "../../common/sanckbar";

function NewsDetails(props: any) {
  let { primary_id } = useParams();
  console.log("new_id", primary_id);
  useEffect(() => {
    getData();
    getNewsCategories();
  }, [primary_id]);

  let news_id = primary_id;

  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    news_category_id: 0,
    news_category: "",
    news_title: "",
    news_content: "",
    news_url: "",
    image_url: "",
    image_id: "",
    status: "inactive",
    status_id: 0,
  });

  const [selectedNewsCategory, setSelectedNewsCategory] = useState<any>({});
  const [newsCategories, setNewsCategories] = useState<any>([]);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  console.log("news", newsCategories);

  const getData = async () => {
    var params = {};
    params = { news_id: Number(primary_id) };
    console.log(params);

    await get(`news/details`, params)
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
        setSelectedNewsCategory({
          news_category: res.data.news_category,
          news_category_id: res.data.news_category_id,
        });
        console.log("selectedNewsCategory", selectedNewsCategory);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getNewsCategories = () => {
    var params = {};
    get("news_category", params)
      .then((response: any) => {
        console.log(response.data.data);
        const filteredNewsCategories = response.data.data.map((item: any) => ({
          news_category_id: item.news_category_id,
          news_category: item.news_category,
        }));
        console.log(filteredNewsCategories);
        // let filteredProductCategories = response.data.data.filter(())
        setNewsCategories(filteredNewsCategories);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(data);
    const newData = {
      news_category: data.news_category,
      news_title: data.news_title,
      news_content: data.news_content,
      news_url: data.news_url,
      image_url: data.image_url,
      image_id: data.image_id,
    };
    console.log("newData", newData);

    try {
      const response = await put(`news/${primary_id}`, newData);
      console.log(response);
      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/news/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const onChangeHandler = async (event: any) => {
    // let file_id: any;
    let folder_name = "news";
    setLoadingLogo(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload(
      "uploadFile/" +
        folder_name +
        "/" +
        news_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          const newData = {
            ...data,
            image_url: res.data.image_url,
            image_id: res.data.image_id,
          };
          setData(newData);
          setLoadingLogo(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  console.log("data", selectedNewsCategory);

  const handleNewsCategory = (event: any) => {
    let news_category_data: any = newsCategories.find(
      (item: any) => item.news_category_id === event.target.value
    );

    setSelectedNewsCategory({
      news_category_id: Number(event.target.value),
    });

    setData({
      ...data,
      news_category_id: event.target.value,
      news_category: news_category_data.news_category,
    });
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  console.log("data.images_lng", data.images_lng?.pl);

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 d-flex justify-content-between align-items-center">
                <h5>News Details</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "5.85rem" }}
                  >
                    <p className="contact-label contact-label-news contact-label-product">
                      News category
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      {selectedNewsCategory.news_category_id !== 0 &&
                        selectedNewsCategory.news_category !== "" && (
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            size="small"
                            // defaultValue={selectedNewsCategory.news_category}
                            value={selectedNewsCategory.news_category_id || ""}
                            disabled
                            // label="Apps"
                            onChange={handleNewsCategory}
                          >
                            {newsCategories.length > 0 &&
                              newsCategories.map((e: any) => (
                                <MenuItem
                                  key={e.news_category_id}
                                  value={e.news_category_id}
                                  // onClick={(event) => handleFoodAppChange(e)}
                                  disableRipple
                                >
                                  {e.news_category}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                    </FormControl>
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">News Title</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_title"
                      value={data.news_title}
                      onChange={handleChange}
                      disabled
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline mb-3"
                    sx={{ gap: "6.25rem" }}
                  >
                    <span className="span-label-news">News Content</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_content"
                      value={data.news_content}
                      onChange={handleChange}
                      disabled
                      multiline
                      rows={4}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline mb-3"
                    sx={{ gap: "8.1rem" }}
                  >
                    <span className="span-label-news">News URL</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_url"
                      value={data.news_url}
                      onChange={handleChange}
                      disabled
                      multiline
                      rows={4}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  {/* <div className="d-flex justify-content-between align-items-center news-button-section">
                    <div className="d-flex align-items-center">
                      <FormControl>
                        <Button
                          variant="contained"
                          size="small"
                          component="label"
                          htmlFor="file-upload-en"
                          className="me-3"
                        >
                          Upload an image
                          <Input
                            disabled={!data.news_content || !data.news_title}
                            id="file-upload-en"
                            type="file"
                            onChange={(e: any) => onChangeHandler(e)}
                            inputProps={{ accept: "image/*" }}
                            className="image-input me-3"
                          />
                        </Button>
                      </FormControl>
                      {loadingLogo ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        data.image_url !== "" && (
                          <div>
                            <img
                              src={data.image_url}
                              className="news-image-edit member-image-new mt-3"
                            />
                          </div>
                        )
                      )}
                    </div>
                    <div className="d-flex justify-content-end">
                      <div></div>
                      <Button type="submit" size="small" variant="contained">
                        Save
                      </Button>
                    </div>
                  </div> */}
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default NewsDetails;
