import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Add from "./New";
import EditMembers from "./Edit";
// import Careers from "./List";

import MembersDetails from "./Details";
import EditCareers from "./Edit";
import CareersDetails from "./Details";
import CareersList from "./List";

function Careers() {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <CareersList />
            </>
          }
        />
        <Route
          path="new"
          element={
            <>
              <Add />
            </>
          }
        />
        <Route
          path="edit/:primary_id"
          element={
            <>
              <EditCareers />
            </>
          }
        />
        <Route
          path="details/:primary_id"
          element={
            <>
              <CareersDetails />
            </>
          }
        />

        <Route index element={<Navigate to="/career_enquiry/list" />} />
      </Route>
    </Routes>
  );
}

export default Careers;
