import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../../../../../utils/api";
import SnackbarAlert from "../../../../common/sanckbar";

function NewCollectionSequence() {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    collection: '',
    sequence_value: 0,
    
  });

  const handleNewCollectionSequence = async (e: any) => {
    e.preventDefault();
    console.log(e);

    let collectionSequenceDetails = {
      collection: formData.collection,
      sequence_value: formData.sequence_value,
      
    };

    try {
      const response = await post("/CollectionSequences", { data: collectionSequenceDetails });
      console.log(response.data);
      if (response) {
        alert("Collection Sequence added successfully");
        navigate("/admin/collection-sequences/list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />

      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>New Collection Sequence</h5>
          </div>
        </div>
        <form
          
          onSubmit={handleNewCollectionSequence}
          style={{ maxWidth: "1152px" }}
        >
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="collection">Collection</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                 
                  required
                  id="collection"
                  className="reg-input name-field"
                  name="collection"
                  value={formData.collection}
                  onChange={handleChange}
                  placeholder="Collection"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="sequenceValue">Sequence Value</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                 
                  required
                  id="sequenceValue"
                  className="reg-input name-field"
                  name="sequence_value"
                  value={formData.sequence_value}
                  onChange={handleChange}
                  placeholder="Sequence Value"
                ></TextField>
              </FormGroup>
            </div>
          </div>





          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewCollectionSequence;
