import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";

function AdminUserDetails() {
  let { primary_id } = useParams();
  useEffect(() => {
    getAdminUserDetails();
  }, [primary_id]);
  const navigate = useNavigate();
  console.log(primary_id);

  const [formData, setFormData] = useState({
    realm: "",
    username: "",
    password: "",
    email: "",
    emailVerified: "",
    user_id: 0,
    first_name: "",
    last_name: "",
    password_confirmation: "",
    role: "",
    status: "",
    status_id: 0,
  });

  const getAdminUserDetails = () => {
    var params = {};
    params = { admin_user_id: Number(primary_id) };

    get(`admin_users/${primary_id}`, {})
      .then((data: any) => {
        // let adminUserData = data.data.data;
        // console.log(adminUserData);
        setFormData(data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(formData);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px" }}
    >
      <div className="row new-client-header">
        <div className="col-xs-12">
          <h5>Admin User Details</h5>
        </div>
      </div>
      <div style={{ maxWidth: "1152px" }}>
        {/* <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Realm</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.realm}</span>
          </div>
        </div> */}

        {/* <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Username</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.username}</span>
          </div>
        </div> */}

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>First Name</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.first_name}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Last Name</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.last_name}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Password</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.password}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Email</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.email}</span>
          </div>
        </div>

        {/* <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Email Verified</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.emailVerified}</span>
          </div>
        </div> */}

        {/* <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>User ID</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.user_id}</span>
          </div>
        </div> */}

        {/* <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Password Confirmation</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.password_confirmation}</span>
          </div>
        </div> */}

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Role</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.role}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Status</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminUserDetails;
