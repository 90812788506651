import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Summary from "./Summary";
import Addgalleries from "./New";
import GalleriesList from "./List";
import Editgalleries from "./Edit";
import GalleriesDetails from "./Details";

function Index() {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <GalleriesList />
            </>
          }
        />
        <Route
          path="new"
          element={
            <>
              <Addgalleries />
            </>
          }
        />
        <Route
          path="edit/:primary_id"
          element={
            <>
              <Editgalleries />
            </>
          }
        />
        <Route
          path="details/:primary_id"
          element={
            <>
              <GalleriesDetails />
            </>
          }
        />

        <Route index element={<Navigate to="/galleries/list" />} />
      </Route>
    </Routes>
  );
}

export default Index;
