import { AddAPhoto } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import React, { useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

function VideoGallery(props: any) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      setIsPlaying(!isPlaying);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.setSelectedVideoId(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  return (
    <Card
      className="album_card album_card_photo"
      // style={{ marginLeft: "100px", marginRight: "100px" }}
    >
      <div
        className="row ss gallery-card-container d-flex "
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        <div className="warning-text-container text-end">
          <h5 className="warning-text">
            Please upload videos in formats such as MP4 with a maximum size of
            500 MB!
          </h5>
        </div>
        {props.data.videos !== undefined || props.data.videos !== null ? (
          <>
            {props.data.videos.map((videoData: any, index: number) => {
              const handleClick = (
                event: React.MouseEvent<HTMLButtonElement>
              ) => {
                setAnchorEl(event.currentTarget);
                props.setSelectedVideoId(videoData.file_id);
              };

              return (
                <div key={index}>
                  <Card
                    className="col-sm-3 gallery-album me-3"
                    id={`long-button-${videoData.file_id}`}
                    style={{ marginRight: "5px" }}
                    sx={{ bgcolor: "#ededf4" }}
                  >
                    <div className="w-50 d-flex justify-content-end mx-auto me-0">
                      <IconButton
                        aria-label="more"
                        id={`long-button-${videoData.file_id}`}
                        aria-controls={
                          open ? `long-menu-${videoData.file_id}` : undefined
                        }
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        size="small"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={`long-menu-${videoData.file_id}`}
                        anchorEl={anchorEl}
                        open={
                          open && props.selectedVideoId === videoData.file_id
                        }
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: 24 * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem
                          id={`long-menu-item-deletevideo${videoData.file_id}`}
                          onClick={() => {
                            props.deleteVideo(videoData.file_id);
                            handleClose();
                          }}
                        >
                          Delete
                        </MenuItem>
                        <MenuItem
                          id={`long-menu-item-setthumb${videoData.file_id}`}
                          onClick={() => props.handleSetThumbnail(videoData)}
                        >
                          Set as thumbnail
                        </MenuItem>
                      </Menu>
                    </div>
                    <CardContent>
                      <div
                        style={{
                          height: "150px", // Set the desired height for the cards
                          overflow: "hidden",
                        }}
                        className="d-flex flex-row justify-content-center align-items-center"
                      >
                        <video
                          width="100%"
                          height="100%"
                          style={{ overflow: "hidden" }}
                          controls
                        >
                          <source src={videoData.video_url} type="video/mp4" />
                        </video>

                        {/* <div>
                          <button
                            onClick={handlePlayPause}
                            style={{
                              padding: "10px 20px",
                              fontSize: "16px",
                              backgroundColor: "#337ab7",
                              color: "#fff",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            {isPlaying ? "Pause" : "Play"}
                            <PlayCircleIcon />
                          </button>
                        </div> */}
                      </div>
                    </CardContent>
                  </Card>
                  <br />
                  <br />
                </div>
              );
            })}
            <div className="add-image-container">
              <Card
                sx={{ bgcolor: "#ededf4", marginTop: "20px" }}
                className="me-3"
              >
                <CardContent>
                  {props.loadingVid ? (
                    <div
                      style={{ height: "200px" }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <CircularProgress size={40} />
                    </div>
                  ) : (
                    <div className="w-100 col-sm-2 gallery-album mt-0 p-0">
                      <div className="w-100 add-image d-flex justify-content-center align-items-center">
                        {/* <AddAPhoto
                        className="add_image_icon"
                        color="disabled"
                      ></AddAPhoto> */}
                        <VideoCallIcon fontSize="large" color="disabled" />
                        <input
                          type="file"
                          multiple
                          accept="video/*"
                          name="videos"
                          onChange={props.onChang3eHandler}
                          className="image_select_input"
                        />
                      </div>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          </>
        ) : (
          <div className="add-image-container">
            <Card
              sx={{ bgcolor: "#ededf4", marginTop: "20px" }}
              className="me-3"
            >
              <CardContent>
                {props.loadingVid ? (
                  <div
                    style={{ height: "200px" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CircularProgress size={40} />
                  </div>
                ) : (
                  <div className="w-100 col-sm-2 gallery-album mt-0 p-0">
                    <div className="w-100 add-image">
                      {/* <AddAPhoto
                      className="add_image_icon"
                      color="disabled"
                    ></AddAPhoto> */}
                      <VideoCallIcon
                        className="add_image_icon"
                        color="disabled"
                        fontSize="large"
                      />
                      <input
                        type="file"
                        multiple
                        accept="video/*"
                        name="videos"
                        onChange={props.onChang3eHandler}
                        className="image_select_input"
                      />
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      <br></br> <br></br>
    </Card>
  );
}

export default VideoGallery;
