import { Link } from "react-router-dom";
import "./login.scss";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { getProfile, login, post, setAccessToken } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import GoogleIcon from "../../layout/assets/images/Login/google-icon.png";
import DelnieLogoIcon from "../../layout/assets/images/Login/delnie-logo.svg";
import LoginRegisterBanner from "../../layout/assets/images/Login/login-register-banner.svg";

import { AppContext } from "../../utils/state";
import SnackbarAlert from "../common/sanckbar";

function Login() {
  const navigate = useNavigate();
  // const context = useContext(AppContext);
  // console.log(AppContext);
  // console.log(context);
  // const { dispatch } = context;

  // const { state, dispatch } = useContext(AppContext);
  const { state, dispatch } = useContext(AppContext);

  // const context = useContext(AppContext);
  // const dispatch = (context as { dispatch: (action: any) => void }).dispatch;

  // const history = useHistory();  //history is the old one

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const response = await login("login", {
        email: formData.email,
        password: formData.password,
      });

      // const response = await post("login", {
      //   email: formData.email,
      //   password: formData.password,
      // });
      if (response.accessToken !== null) {
        // await localStorage.setItem("id_token", response.data.api_token);

        // if (response.accessToken) {
        await localStorage.setItem(
          "accessToken",
          "JWT " + response.accessToken
        );
        // await localStorage.setItem("accessToken", response.data.api_token);
        setAccessToken("JWT " + response.accessToken);
        console.log(state);

        // state.accessToken = response.data.api_token;
        state.accessToken = response.accessToken;
        state.user = response.user;
        state.isAuthenticated = true;
        dispatch({
          type: "setAuth",
          data: true,
        });
        // fetchProfile();
        console.log(state);

        alert(response.message ? response.message : "success", "success");
        setTimeout(() => {
          navigate("/products/list");
        }, 1000);
        // setTimeout(() => {
        //   navigate("/dashboard");
        // }, 1000);
      } else {
        alert(response.message ? response.message : "not working", "warning");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
      // history.push('/new-route');

      // Process the response from the API here
    } catch (err: any) {
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.error(err);
    }
  };

  const fetchProfile = async () => {
    console.log("hhhh");
    let data = await getProfile();
    console.log(data);
    state.user = data.user;
    state.isAuthenticated = true;
    dispatch({
      type: "setAuth",
      data: true,
    });
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row login-page">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 login-rt-cntnr">
          <img src={LoginRegisterBanner} alt="" className="w-100" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
          <div>
            <div className="logo-cntr">
              {/* <img src={DelnieLogoIcon} className="logo" alt="logo" /> */}
              <span className="company-name-first-part"> Acceron </span>{" "}
              <span className="company-name-second-part">
                {" "}
                - Delnie Cloud Admin
              </span>
            </div>
            <h1 className="heading login-heading">Sign In</h1>

            {/* <GoogleButton className="google-btn" >Google</GoogleButton> */}

            <form onSubmit={handleSubmit}>
              <TextField
                className="form email-field"
                id="outlined-basic"
                type="email"
                value={formData.email}
                onChange={(e: any) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                label="Email"
                variant="outlined"
                sx={{ width: "100%" }}
              />
              <br />

              <TextField
                className="form password-field"
                id="outlined-basic"
                type="password"
                value={formData.password}
                onChange={(e: any) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                label="Password"
                variant="outlined"
              />
              {/* <div className="forgot-password">
              <Link
                to="/forgot-password"
                className="d-flex justify-content-center"
              >
                forgot password
              </Link>
            </div> */}
              <br />

              <input type="submit" className="login-btn" value="Login"></input>
            </form>
            {/* <div className="login-google-option">
            <h1>or</h1>
            <button className="btn google-btn">
              <img src={GoogleIcon} alt="" className="google-icon" />
              <span> Sign in with Google</span>
            </button>
          </div> */}

            <p style={{ marginBottom: "80px" }}>
              <Link to={"/register"} className="sign-up">
                {" "}
                Create an account
              </Link>
            </p>
          </div>
        </div>
        {/* col-6 end */}
      </div>
    </>
  );
}

export default Login;
