import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { get, post } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { CollectionSequencesModel } from "./models/CollectionSequencesModel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import SnackbarAlert from "../../common/sanckbar";

type Props = {};

function EditCollectionSequenceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);
  // })
  const [formData, setFormData] = useState({
    collection: '',
    sequence_value: '',
   
    
  });

  useEffect(() => {
    getCollectionSequenceDetails();
  }, []);
  const getCollectionSequenceDetails = () => {
    var params = {};
    params = { collection_sequence_id: id };

    get("CollectionSequences/getCollectionSequenceById", params)
      .then((data: any) => {
        let collectionSequenceData = data.data.data;
        console.log(collectionSequenceData);
        setFormData(collectionSequenceData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(formData);

  const handleNewCollectionSequence = async (e: any) => {
    e.preventDefault();
    console.log(e);

    let collectionSequenceDetails = {
      collection: 'collection',
      sequence_value: 'sequence_value',
    
    };
    console.log(collectionSequenceDetails);

    try {
      var params = { collection_sequence_id: Number(id), data: formData };

      post("CollectionSequences/updateCollectionSequenceDetails", params).then(function (data) {
        console.log(data);
        if (data) {
          navigate(`/admin/collection-sequences/details/${id}`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>New Collection Sequence</h5>
          </div>
        </div>
        <form
         
          onSubmit={handleNewCollectionSequence}
          style={{ maxWidth: "1152px" }}
        >
           <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="collection">Collection</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                 
                  required
                  id="collection"
                  className="reg-input name-field"
                  name="collection"
                  value={formData.collection}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="Collection"
                ></TextField>
              </FormGroup>
            </div>
          </div>
        
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="sequenceValue">Sequence Value</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                 
                  required
                  id="sequenceValue"
                  className="reg-input name-field"
                  name="sequence_value"
                  value={formData.sequence_value}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="Sequence Value"
                ></TextField>
              </FormGroup>
            </div>
          </div>

         

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default EditCollectionSequenceDetails;
