import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { Button, Card, CardContent } from "@mui/material";
import { getProfile, post } from "../../../utils/api";
import SnackbarAlert from "../../common/sanckbar";
import { AppContext } from "../../../utils/state";

function AddGalleries(props: any) {
  const { state, dispatch } = useContext(AppContext);

  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    album_name: "",
    // album_name_lng: {
    //   en: "",
    //   pl: "",
    //   ta: "",
    // },
    thumbnail: {
      image_id: "",
      image_url: "",
    },
    images: [],
    videos: [],
    status: "inactive",
    status_id: 0,
  });
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const [isAlbumCreated, setIsAlbumCreated] = useState(false);
  let [albumId, setAlbumId] = useState(0);
  let [galleriesId, setGalleriesId] = useState(0);

  useEffect(() => {
    if (!state.user._id) {
      fetchProfile();
    }
  }, []);

  const fetchProfile = async () => {
    console.log("hhhh");
    let data = await getProfile();
    console.log(data);
    state.user = data.user;
    state.isAuthenticated = true;
    dispatch({
      type: "setAuth",
      data: true,
    });
  };

  const handleSubmit = async (e: any) => {
    handleCreateAlbum();
    e.preventDefault();
    console.log(data);
    const newData = { ...data };

    try {
      const response = await post("galleries", newData);
      console.log(response);
      setData(newData);
      console.log(newData);

      if (response) {
        setAlbumId(response._id);
        setGalleriesId(response.galleries_id);
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/galleries/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };
  console.log(albumId);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleCreateAlbum = () => {
    setIsAlbumCreated(true);
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h5>Add galleries</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">Album Name</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="album_name"
                      value={data.album_name}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-end align-items-center news-button-section">
                    <div className="d-flex justify-content-end mb-3">
                      <div></div>
                      <Button
                        // disabled={!isTranslated}
                        type="submit"
                        variant="contained"
                        size="small"
                        // onClick={handleCreateAlbum}
                      >
                        Create Album
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AddGalleries;
