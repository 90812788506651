import React from 'react';
import './Footer.scss';

export default function Footer() {
    return (
        <>
            <div className="container d-flex justify-content-end align-items-center footer-main">
                <div className="row">
                    <div className="col-xs-12">
                        <h6 className='para-margin'>© Copyright 2017 Delnie Pvt Ltd. All Rights Reserved. Privacy Policy</h6>
                    </div>
                </div>
            </div>
        </>
    )
};
