import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { awsupload, get, patch, put } from "../../../utils/api";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PlusIcon from "../../../layout/assets/images/Galleries/plus-icon.png";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  FormControl,
  FormGroup,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import "./index.scss";
import { Add, AddAPhoto } from "@mui/icons-material";
import SnackbarAlert from "../../common/sanckbar";
import Tabswitch from "../../../layout/components/Tabswitch/Tabswitch";
import PhotoGallery from "./GalleryComponents/photo";
import VideoGallery from "./GalleryComponents/video";

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          width: "calc(33.33% - 16px)",
          marginBottom: "16px",
          "@media (max-width: 959.95px)": {
            width: "calc(50% - 16px)",
          },
          "@media (max-width: 599.95px)": {
            width: "100%",
          },
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          paddingTop: "56.25%",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: "16px",
          },
        },
      },
    },
  },
  status: {
    danger: "#FF5555",
  },
});

function GalleriesDetails() {
  let { primary_id } = useParams();
  const navigate = useNavigate();
  let [data, setData] = useState<any>({
    album_name: "",
    // album_name_lng: {
    //   en: "",
    //   pl: "",
    //   ta: "",
    // },
    thumbnail: {
      image_id: "",
      image_url: "",
    },
    images: [],
    videos: [],
    status: "",
    status_id: 0,
  });

  useEffect(() => {
    getData();
    // console.log(data.images);
  }, [primary_id]);

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingVid, setLoadingVid] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  // let [albumId, setAlbumId] = useState("");
  // let [galleriesId, setGalleriesId] = useState(0);

  const getData = () => {
    var params = {};
    params = { album_id: Number(primary_id) };
    console.log(params);

    get(`galleries/details`, params)
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    // const album_id = data.album_id;
    // if (data.images.length > 0) {
    console.log(data);
    const newData = {
      ...data,
    };
    console.log(newData);

    try {
      const response = await put(`galleries/${primary_id}`, newData);
      console.log(response);
      if (response) {
        // navigate("/galleries/list");
        // getData();
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const onChang2eHandler = async (event: any) => {
    console.log("images", data.images);

    const files = event.target.files;
    const formData = new FormData();
    let bucketname = "acceron-public";
    let album_id = data.album_id;
    let dbname = "acceron-dev";
    let folder_name = "galleries";

    const allowedFormats = ["jpg", "jpeg", "png"];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        alert(
          "Please upload your image in jpg, jpeg, or png format only",
          "warning"
        );
        return; // Exit the function if an invalid format is found
      }
      formData.append("files", file, file.name);
    }

    // formData.append("dbName", "acceron-dev");
    // formData.append("bucketName", "acceron-private");

    formData.append("folderName", "galleries");

    awsupload(
      "uploadMultipleImages" +
        // dbname +
        // "/" +
        // bucketname +
        "/" +
        folder_name +
        "/" +
        album_id,
      formData
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          console.log(res.data.urls);

          alert(res.message ? res.message : "success", "success");

          if (res.data.urls.length > 0) {
            const { urls } = res.data;
            if (urls.length > 0) {
              const updatedData = { ...data, images: urls };
              setData(updatedData);
              data.images = updatedData.images;
              setData(data);

              console.log(data);
              handleSubmit();
            }
            console.log("data", data);
          }
          // do something with the response
        }
      })
      .catch((err: any) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred while submitting the form. Please try again.";
        alert(errorMessage, "warning");
        console.error(err);
      });
  };

  const onChang3eHandler = async (event: any) => {
    setLoadingImg(true);
    console.log("images", data.images);

    const files = event.target.files;
    const formData = new FormData();
    let bucketname = "acceron-public";
    let album_id = data.album_id;
    let dbname = "acceron-dev";
    let folder_name = "galleries";

    const allowedFormats = ["jpg", "jpeg", "png"];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (!allowedFormats.includes(fileExtension)) {
        alert(
          "Please upload your image in jpg, jpeg, or png format only",
          "warning"
        );
        return; // Exit the function if an invalid format is found
      }
      formData.append("files", file, file.name);
    }

    // formData.append("dbName", "acceron-dev");
    // formData.append("bucketName", "acceron-private");

    formData.append("folderName", "galleries");

    awsupload(
      "uploadMultipleImages/" +
        // bucketname +
        // "/" +
        folder_name +
        "/" +
        album_id,
      formData
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          console.log(res.data.urls);

          alert(res.message ? res.message : "success", "success");

          if (res.data.urls.length > 0) {
            const { urls } = res.data;
            if (urls.length > 0) {
              const updatedData = {
                ...data,
                images: [...data.images, ...urls],
              };
              // addImage(urls);
              setData(updatedData);
              data.images = updatedData.images;
              setData(data);

              console.log(data);
              handleSubmit();
            }
            console.log("data", data);
          }
          setLoadingImg(false);
          // do something with the response
        }
      })
      .catch((err: any) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred while submitting the form. Please try again.";
        alert(errorMessage, "warning");
        console.error(err);
      });
  };

  const onChang4eHandler = async (event: any) => {
    setLoadingVid(true);
    console.log("videos", data.videos);

    const files = event.target.files;
    const formData = new FormData();
    let bucketname = "acceron-public";
    let album_id = data.album_id;
    let dbname = "acceron-dev";
    let folder_name = "galleries";

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append("files", file, file.name);
    }

    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];

    formData.append("dbName", "acceron-dev");
    formData.append("bucketName", "acceron-public");

    formData.append("folderName", "galleries");

    awsupload(
      "s3VideoUploadPublicWithCollectionId/" +
        bucketname +
        "/" +
        folder_name +
        "/" +
        album_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      formData
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);

          const videoData = {
            file_id: res.data.file_id,
            video_url: res.data.video_url,
          };

          alert(res.message ? res.message : "success", "success");

          if (
            res.data.video_url !== null ||
            res.data.video_url !== undefined ||
            res.data.video_url !== ""
          ) {
            const videoData = {
              file_id: res.data.file_id,
              video_url: res.data.video_url,
            };
            const updatedData = [...data.videos, videoData];
            console.log("working", updatedData);
            const { videos } = res.data;
            // addVideo(videos);
            data = { ...data, videos: updatedData };
            setData(data);
            setData((prevData: any) => {
              return { ...prevData, videos: updatedData };
            });

            console.log("data", data);
            handleSubmit();
          }
          console.log("data", data);

          setLoadingVid(false);
          // do something with the response
        }
      })
      .catch((err: any) => {
        const errorMessage =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "An error occurred while submitting the form. Please try again.";
        alert(errorMessage, "warning");
        console.error(err);
      });
  };

  const deleteImage = async (image_id: any) => {
    console.log(image_id);
    let newData = { image_id: image_id };
    try {
      const response = await patch(`galleries/${primary_id}`, newData);
      console.log(response);
      if (response) {
        getData();
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const deleteVideo = async (file_id: any) => {
    console.log(file_id);
    let newData = { file_id: file_id };
    try {
      const response = await patch(
        `galleries/${primary_id}/deleteVideo`,
        newData
      );
      console.log(response);
      if (response) {
        getData();
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleThumbNail = async (e: any) => {
    console.log(e);
    await setData((prevData: any) => ({
      ...prevData,
      thumbnail: {
        ...prevData.thumbnail,
        image_id: e.image_id,
        image_url: e.s3Url,
      },
    }));
    console.log(data);
  };
  console.log(data);
  // const addImage = async (images: any) => {
  //   console.log(images);
  //   let new_data = { images: images };
  //   try {
  //     const response = await patch(
  //       `galleries/addImage/${primary_id}`,
  //       new_data
  //     );
  //     console.log(response);
  //     if (response) {
  //       getData();
  //     }
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  // };

  const addVideo = async (videos: any) => {
    console.log(videos);
    let new_data = { videos: videos };
    console.log(new_data);
    try {
      const response = await patch(
        `galleries/addVideo/${primary_id}`,
        new_data
      );
      console.log(response);
      if (response) {
        getData();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedImageId(null); // Reset the selected image ID
    setSelectedVideoId(null);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);

  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

  const open = Boolean(anchorEl);

  const handleSetThumbnail = (imageData: any) => {
    handleThumbNail(imageData);
    console.log(imageData.s3Url);
    handleClose();
  };

  const tabData = [
    {
      tabName: "Photos",
      // tabLink: "#",
      tabComponent: (
        <PhotoGallery
          data={data}
          selectedImageId={selectedImageId}
          setSelectedImageId={setSelectedImageId}
          deleteImage={deleteImage}
          handleSetThumbnail={handleSetThumbnail}
          onChang3eHandler={onChang3eHandler}
          loadingImg={loadingImg}
        />
      ),
    },
    {
      tabName: "Videos",
      // tabLink: "#",
      tabComponent: (
        <VideoGallery
          data={data}
          selectedVideoId={selectedVideoId}
          setSelectedVideoId={setSelectedVideoId}
          deleteVideo={deleteVideo}
          handleSetThumbnail={handleSetThumbnail}
          onChang3eHandler={onChang4eHandler}
          loadingVid={loadingVid}
        />
      ),
    },
  ];

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-details-container">
          <Card style={{ height: "auto" }}>
            <CardContent>
              <br></br>
              <div className="row">
                <div className="col-sm-6">
                  <h5>
                    Album Name : {data.album_name}{" "}
                    <Tooltip title="Please upload images in jpeg, jpg or png format only!">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </h5>
                </div>
                <div className="col-sm-6 text-center"></div>
              </div>

              <div className="row mb-3">
                <div className="col-xs-12 photo-video-switch">
                  <Tabswitch tabData={tabData} />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default GalleriesDetails;
