import { Chip, FormGroup, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import Rightbar from "../../Rightbar/Rightbar";
import Tabheader from "../../Tabheader/Tabheader";
import Table from "../../Table/Table";
import { get, post } from "../../../../utils/api";
import ListHeader from "./ListHeader";

export default function ListModule(props: any) {
  console.log(props);
  const pathname = window.location.pathname;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<Array<any>>([]);
  const [checked, setChecked] = useState<Array<any>>([]);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<any>({});
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [invoiceSidebarStatus, setInvoiceSidebarStatus] = useState(false);
  const [financialYear, setFinancialYear] = useState<any>([]);
  const [sortBy, setSortBy] = useState<any>({ _id: 1 });
  const [sortedData, setSortedData] = useState<{ invoice_no: number }[]>([]);

  const [columns, setColumns] = useState<Array<String>>([]);
  const [columnFields, setColumnFields] = useState<Array<any>>([]);
  const [filterData, setFilterData] = React.useState<any>([]);
  const [fieldsMaster, setFieldsMaster] = useState<Array<any>>([]);
  // const [detailViewModal, setDetailViewModal] = useState({
  //   status: false,
  //   id: "",
  // });
  useEffect(() => {
    getData();
    // getInvoiceDetails();
    // getFYData();
  }, [page, rowsPerPage, filterData, sortBy]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const nextPage = newPage + 1;
    setPage(nextPage);
    console.log("workinghandle2", nextPage);
  };

  const handleDownload = () => {
    console.log("page", page);
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
      sortBy: sortBy,
    };

    get(`${props.module}/pathToYourDownload`, params)
      .then((res) => {
        const encoder = new TextEncoder();
        const encodedData = encoder.encode(res.data);
        const decoder = new TextDecoder("utf-8");
        const decodedData = decoder.decode(encodedData);
        const blob = new Blob([decodedData], {
          type: "text/csv;charset=utf-8",
        });
        const url = URL.createObjectURL(blob);
        console.log("encodedData", url);
        console.log(res.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        link.click();
        setChecked([]);
        setIsHeaderChecked(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFinancialYear(event.target.value as number);
  };

  // useEffect(() => {
  //   getData();
  // }, [newPage, newRowsPerPage]);

  // let tabData = [
  //   {
  //     tabName: "Invoice Details",
  //     tabLink: "#",
  //   },
  //   {
  //     tabName: "Purchase Details",
  //     tabLink: "#",
  //   },
  // ];

  const getData = () => {
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
      sortBy: sortBy,
    };
    get(`${props.module}`, params).then(async (response) => {
      await setData(response.data.data);
      await setTotalCount(response.data.totalCount);
      await setFieldsMaster(response.data.fields);
    });
  };
  const handleFieldStatus = (cindex: any) => {
    const columnsFields = fieldsMaster.map((e: any, index: number) => {
      return {
        ...e,
        coloumn_status: cindex === index ? !e.coloumn_status : e.coloumn_status,
      };
    });
    setFieldsMaster(columnsFields);
  };

  const getInvoiceDetails = (id: any) => {
    get(`${props.module}/${id}`, {}).then((response) => {
      setInvoiceDetails(response.data);
    });
  };

  const handleSortBy = async (key: string) => {
    if (sortBy[key] == 1) {
      let sortData = { [key]: -1 };
      await setSortBy(sortData);
    } else {
      let sortData = { [key]: 1 };
      await setSortBy(sortData);
    }
  };

  const handleUpdate = async (data: any) => {
    let newData = data;
    post(`${props.module}/updateMultiple`, {
      ids: checked,
      updateData: newData,
    })
      .then((res) => {
        setChecked([]);
        setIsHeaderChecked(false);
        getData();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const getFYData = () => {
  //   get(`FYSettings?page=${page}&rowsPerPage=${rowsPerPage}`, {}).then(
  //     (response) => {
  //       setFinancialYear(response.data);
  //       console.log(response.data);
  //     }
  //   );
  // };

  const showRightSidebar = (data: any) => {
    setInvoiceSidebarStatus(data.status);
    setSelectedInvoiceData(data.data);
    getInvoiceDetails(data.data.invoice_no);
  };

  const handleClick = (e: any) => {
    props.drawerClick(e);
  };

  return (
    <div className="row">
      <div className="col-xs-12 pt-2">
        {/* {pathname.includes("/sales/invoices") && (
          <Rightbar
            status={invoiceSidebarStatus}
            showRightSidebar={setInvoiceSidebarStatus}
            headingContent={
              <InvoiceRightbarHeader headerData={selectedInvoiceData} />
            }
            tabContent={
              <Tabswitch tabData={tabData} style={{ color: "#FFF" }} />
            }
            bodyContent={
              <InvoiceRightbarBody currentInvoiceDetails={setInvoiceDetails} />
            }
            bodySubContent={<InvoiceRightbarSubBody />}
            footerContent={<InvoiceRightbarFooter />}
            invoiceDetails={invoiceDetails}
          />
        )} */}
        <ListHeader
          totalCount={totalCount}
          fieldsMaster={fieldsMaster}
          module={props.module}
          modulePath={props.modulePath}
          getData={getData}
          id={props.id}
          filterData={filterData}
          setFilterData={setFilterData}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          financialYear={financialYear}
          handleChange={handleChange}
          handleUpdate={handleUpdate}
          handleDownload={handleDownload}
          moduleId={`${props.module}_id`}
        />

        <Table
          data={data}
          detailLink={props.modulePath + "/details/"}
          editLink={props.modulePath + "/edit/"}
          module={props.module}
          columns={columns}
          checked={checked}
          setChecked={setChecked}
          getData={getData}
          isHeaderChecked={isHeaderChecked}
          setIsHeaderChecked={setIsHeaderChecked}
          showRightSidebar={showRightSidebar}
          rightSidebarStatus={invoiceSidebarStatus}
          primary_id={props.primary_id}
          fields={fieldsMaster}
          setId={props.setId}
          handleFieldStatus={handleFieldStatus}
          sortBy={sortBy}
          handleSortBy={handleSortBy}
        />

        {/* <DetailViewModal
          detailsModule={props.detailsModule}
          detailViewModal={detailViewModal}
          setDetailViewModal={setDetailViewModal}
        /> */}
      </div>
    </div>
  );
}
