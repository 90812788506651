import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";

import Rightbar from "../../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../../layout/components/Selectmenu/Selectmenu";
import Tabheader from "../../../../../layout/components/Tabheader/Tabheader";
import Table from "../../../../../layout/components/Table/Table";
import Tabledata from "../../../../../layout/components/Table/Table";
import Tabswitch from "../../../../../layout/components/Tabswitch/Tabswitch";
import { get } from "../../../../../utils/api";

import CreateInvoiceIcon from "../../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { Link } from "react-router-dom";

function List() {
  useEffect(() => {
    fetchData({}, page,rowsPerPage);
  }, []);

  const [data, setData] = useState<Array<any>>([]);
  const [totalCount, setTotalCount] = useState<Number>(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [columns, setColumns]  = useState<Array<String>>([]);

  const handleChangePage = async (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    fetchData({}, newPage,rowsPerPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchData({},1,parseInt(event.target.value, 10));

  };


  const fetchData = (filter = {}, page_no: number=1, limit: number = 10) => {
    get("field_settings", {filter:filter, page:page_no,limit:limit}).then((response) => {
      setData(response.data.data);
      console.log(response.data.data);
      let fields=response.data.fields;
      console.log(fields);
      let columnsFields = [
        "collection_name",
        "fields",
      ];

      columnsFields.push("actions");
      console.log(columnsFields);
      setColumns(columnsFields);
      setTotalCount(response.data.totalCount);
    });
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <Rightbar />
        <div className="row">
          <div className="col-xs-12">
            <div className="d-md-flex d-block justify-content-between align-items-center tabheader-main">
              <div className="invoice-details" style={{ flexGrow: "50" }}>
                <h3>Field Settings</h3>
                <p>
                  <>{totalCount} Field Settings</>
                </p>
              </div>
              <div className="d-md-flex d-block justify-content-between align-items-center invoice-modify-btns">
                {/* <Selectmenu
                  names={["FY-22-23", "FY-22-24"]}
                  className="select-main"
                  selectClass="select-inner"
                /> */}
                {/* <Dropdown
          name="FY-22-23"
          color="rgba(9, 65, 78, 0.12)"
          className="invoice-filter-btn"
          buttonClass="filter-btn"
          endIcon={<DropdownIcon iconclass="" />}
        /> */}
                <Button
                  variant="contained"
                  color="error"
                  className="invoice-download-btn"
                >
                  <span> Download</span>
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  className="invoice-create-btn"
                >
                  <img src={CreateInvoiceIcon} alt="" />
                  <Link to="/admin/field-settings/new-field-setting">
                    <span> New Field Setting</span>
                  </Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          data={data}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalCount}
          detailLink={"/admin/field-settings/details/"}
          editLink={"/admin/field-settings/edit-field-setting/"}
          primary_id="_id"
        />
      </div>
    </div>
  );
}

export default List;

