import { lazy, FC, Suspense, useEffect, useContext } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Summary from "../layout/components/Summary/Summary";
import Tabledata from "../layout/components/Table/Table";
import MasterLayout from "../master-layout";
import AdminUsers from "../modules/home/admin/admin-users";
import Dashboard from "../modules/home/dashboard/dashboard";
import FieldSettings from "../modules/home/admin/field-settings";
import FYSettings from "../modules/home/admin/settings";
import CollectionSequences from "../modules/home/admin/collection-sequences";
import News from "../modules/news";
import NewsCategory from "../modules/news_category";
import ProductCategory from "../modules/product_category";
import Galleries from "../modules/galleries";
import { AppContext } from "../utils/state";
import { useAuth } from "../modules/auth";
import { getProfile } from "../utils/api";
import Profile from "../modules/profile";
import Products from "../modules/products";
// import Careers from "../modules/careers";
import ProductEnquiry from "../modules/product_enquiry";
import ProductVariety from "../modules/product_variety";
import CareerEnquiry from "../modules/career_enquiry";
import GeneralEnquiry from "../modules/general_enquiry";

const PrivateRoutes = () => {
  const location = useLocation();
  console.log(location);
  const { state, dispatch } = useContext(AppContext);
  const { currentUser } = useAuth();
  let accessToken = "" + localStorage.getItem("accessToken");
  useEffect(() => {
    console.log("in private*******************");
    if (!state.user._id) {
      fetchProfile();
    }
  }, [location]);

  // useEffect(() => {
  //   console.log("in private*******************");
  //   if (!state.user._id) {
  //     logout(); // Call the logout function when user ID is not present
  //   } else {
  //     fetchProfile();
  //   }
  // }, [location]);

  const fetchProfile = async () => {
    let data = await getProfile();
    console.log(data);
    state.user = data.user;
    state.isAuthenticated = true;
    dispatch({
      type: "setAuth",
      data: true,
    });
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/admin/field-settings/*" element={<FieldSettings />} />
        <Route path="/news/*" element={<News />} />
        <Route path="/news_category/*" element={<NewsCategory />} />
        <Route path="/product_category/*" element={<ProductCategory />} />
        <Route path="/product_variety/*" element={<ProductVariety />} />
        <Route path="/products/*" element={<Products />} />
        <Route path="/career_enquiry/*" element={<CareerEnquiry />} />
        <Route path="/general_enquiry/*" element={<GeneralEnquiry />} />
        <Route path="/product_enquiry/*" element={<ProductEnquiry />} />
        <Route path="/galleries/*" element={<Galleries />} />
        <Route path="/admin/admin-users/*" element={<AdminUsers />} />
        <Route path="/admin/settings/*" element={<FYSettings />} />
        <Route
          path="/admin/collection-sequences/*"
          element={<CollectionSequences />}
        />
        <Route path="/summary" element={<Summary />} />
        <Route index element={<Navigate to="/products" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
