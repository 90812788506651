import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Add from "./New";
import EditMembers from "./Edit";
import MembersList from "./List";
import MembersDetails from "./Details";
import EditProducts from "./Edit";
import ProductsDetails from "./Details";
import AddProducts from "./New";

function Products() {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <MembersList />
            </>
          }
        />
        <Route
          path="new"
          element={
            <>
              <AddProducts />
            </>
          }
        />
        <Route
          path="edit/:primary_id"
          element={
            <>
              <EditProducts />
            </>
          }
        />
        <Route
          path="details/:primary_id"
          element={
            <>
              <ProductsDetails />
            </>
          }
        />

        <Route index element={<Navigate to="/products/list" />} />
      </Route>
    </Routes>
  );
}

export default Products;
