import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";

import Rightbar from "../../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../../layout/components/Selectmenu/Selectmenu";
import Tabheader from "../../../../../layout/components/Tabheader/Tabheader";
import Table from "../../../../../layout/components/Table/Table";
import Tabledata from "../../../../../layout/components/Table/Table";
import Tabswitch from "../../../../../layout/components/Tabswitch/Tabswitch";
import { get } from "../../../../../utils/api";

import CreateInvoiceIcon from "../../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { Link } from "react-router-dom";
import ListModule from "../../../../../layout/components/CRUD/List/List";

function List() {
  const [id, setId] = useState(0);

  return (
    <ListModule
      module="admin_users"
      modulePath="/admin/admin-users"
      primary_id="admin_user_id"
      id={id}
    />
  );
}

export default List;
