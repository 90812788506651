import React, { useState } from "react";
import { ReactDOM } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import DropdownOpenerIcon from "../../assets/images/Sidebar/dropdown-open-arrow.svg";
import DropdownCloserIcon from "../../assets/images/Sidebar/dropdown-close-arrow.svg";
import OrgDropdown from "../OrgDropdown";
import DropdownIcon from "../Dropdown/DropdownIcon";

function Sidebar(props: any) {
  const location = useLocation();
  const [openSalesMenu, setOpenSalesMenu] = useState(true);
  const [openWebsiteMenu, setOpenWebsiteMenu] = useState(true);
  const [openPurchasesMenu, setOpenPurchasesMenu] = useState(true);
  const [openExpensesMenu, setOpenExpensesMenu] = useState(true);
  const [openTripMenu, setOpenTripMenu] = useState(true);
  const [openMasterMenu, setOpenMasterMenu] = useState(true);
  const [openBankMenu, setOpenBankMenu] = useState(true);
  const [openAdminMenu, setOpenAdminMenu] = useState(true);
  const [openAccountingMenu, setOpenAccountingMenu] = useState(true);

  const [sideNav, setSideNav] = useState(true);
  const [msg, setMsg] = useState(true);

  const handleSalesDropdownOpen = () => {
    setOpenSalesMenu(!openSalesMenu);
  };

  const handleWebsiteDropdownOpen = () => {
    setOpenWebsiteMenu(!openWebsiteMenu);
  };

  const handlePurchasesDropdownOpen = () => {
    setOpenPurchasesMenu(!openPurchasesMenu);
  };

  const handleExpensesDropdownOpen = () => {
    setOpenExpensesMenu(!openExpensesMenu);
  };

  const handleTripDropdownOpen = () => {
    setOpenTripMenu(!openTripMenu);
  };

  const handleMasterDropdownOpen = () => {
    setOpenMasterMenu(!openMasterMenu);
  };

  const handleBankDropdownOpen = () => {
    setOpenBankMenu(!openBankMenu);
  };

  const handleAdminDropdownOpen = () => {
    setOpenAdminMenu(!openAdminMenu);
  };

  const handleAccountingDropdownOpen = () => {
    setOpenAccountingMenu(!openAccountingMenu);
  };

  return (
    <>
      {sideNav ? (
        <div className="sidebar">
          <a
            className="sidenav_head d-flex justify-content-between align-items-center"
            href="#home"
          >
            {/* <span className="sidenav_head_title"> {props.dropdownValue} </span> */}
            <span className="sidenav_head_title"> Acceron </span>
          </a>

          <RxCross2
            className="sidebar_cross"
            onClick={() => {
              setSideNav(false);
              props.handleClick(msg);
            }}
          />
          {/* <Link
            to="/dashboard"
            className={
              location.pathname.match("/dashboard")
                ? "active sidenav-sub-item sidenav-dashboard-item"
                : "sidenav-sub-item sidenav-dashboard-item"
            }
          >
            Dashboard
          </Link> */}

          <div
            onClick={handleWebsiteDropdownOpen}
            className={
              openWebsiteMenu
                ? "sidenav-sub-heading"
                : "sidenav-sub-heading sidenav-item-spacing"
            }
          >
            <img
              src={openWebsiteMenu ? DropdownCloserIcon : DropdownOpenerIcon}
              alt=""
            />{" "}
            <span>WEBSITE</span>{" "}
          </div>

          {openWebsiteMenu && (
            <div className="sidebar-dropdown-items">
              <Link
                to="/news/list"
                className={
                  location.pathname.match(/^\/news(\/|$)/) ? "active" : ""
                }
              >
                News
              </Link>
              {/* <Link
                to="/blogs"
                className={location.pathname.match("/blogs") ? "active" : ""}
              >
                Blog
              </Link> */}
              {/* <Link
                to="/events"
                className={location.pathname.match("/events") ? "active" : ""}
              >
                Events
              </Link> */}

              <Link
                to="/career_enquiry"
                className={
                  location.pathname.match("/career_enquiry") ? "active" : ""
                }
              >
                Career Enquiry
              </Link>

              <Link
                to="/product_enquiry"
                className={
                  location.pathname.match("/product_enquiry") ? "active" : ""
                }
              >
                Product Enquiry
              </Link>

              <Link
                to="/general_enquiry"
                className={
                  location.pathname.match("/general_enquiry") ? "active" : ""
                }
              >
                General Enquiry
              </Link>

              <Link
                to="/galleries"
                className={
                  location.pathname.match("/galleries") ? "active" : ""
                }
              >
                Gallery
              </Link>
            </div>
          )}

          <div
            onClick={handleMasterDropdownOpen}
            className={
              openMasterMenu
                ? "sidenav-sub-heading"
                : "sidenav-sub-heading sidenav-item-spacing"
            }
          >
            <img
              src={openMasterMenu ? DropdownCloserIcon : DropdownOpenerIcon}
              alt=""
            />{" "}
            <span>MASTER DATA</span>{" "}
          </div>
          {openMasterMenu && (
            <div className="sidebar-dropdown-items">
              <Link
                to="/news_category/list"
                className={
                  location.pathname.match(/^\/news_category(\/|$)/)
                    ? "active"
                    : ""
                }
              >
                News Category
              </Link>
              <Link
                to="/product_category"
                className={
                  location.pathname.match("/product_category") ? "active" : ""
                }
              >
                Product Category
              </Link>
              <Link
                to="/products"
                className={location.pathname.match("/products") ? "active" : ""}
              >
                Products
              </Link>
              <Link
                to="/product_variety"
                className={
                  location.pathname.match("/product_variety") ? "active" : ""
                }
              >
                Product Variety
              </Link>
            </div>
          )}

          <div
            onClick={handleAdminDropdownOpen}
            className={
              openAdminMenu
                ? "sidenav-sub-heading"
                : "sidenav-sub-heading sidenav-item-spacing"
            }
          >
            <img
              src={openAdminMenu ? DropdownCloserIcon : DropdownOpenerIcon}
              alt=""
            />{" "}
            <span>ADMIN</span>{" "}
          </div>
          {openAdminMenu && (
            <div className="sidebar-dropdown-items">
              <Link
                to="/admin/admin-users"
                className={
                  location.pathname.match("/admin/admin-users") ? "active" : ""
                }
              >
                Admin Users
              </Link>
              {/* <Link
                to="/admin/languages"
                className={
                  location.pathname.match("/admin/languages") ? "active" : ""
                }
              >
                Languages
              </Link> */}
            </div>
          )}
        </div>
      ) : (
        <div className="sidebar sidebar_collapsed">
          <a
            className="sidenav_collapsed_head d-flex justify-content-between align-items-center"
            href="#home"
          >
            <FaBars
              className="fabars-color"
              onClick={() => {
                setSideNav(true);
                props.handleClick(!msg);
              }}
            />
          </a>
        </div>
      )}
    </>
  );
}

export default Sidebar;
