import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import Rightbar from "../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../layout/components/Selectmenu/Selectmenu";
import Tabheader from "../../../../layout/components/Tabheader/Tabheader";
import Table from "../../../../layout/components/Table/Table";
import Tabledata from "../../../../layout/components/Table/Table";
import Tabswitch from "../../../../layout/components/Tabswitch/Tabswitch";
import { get } from "../../../../utils/api";

import CreateInvoiceIcon from "../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import List from "./List";
import Header from "./Header/index";
// import Summary from "./Summary/index";
import NewAdminuser from "../admin-users/New/index";
import EditAdminuserDetails from "./edit";
import AdminuserDetails from "../admin-users/details";
import NewModule from "../../../../layout/components/CRUD/New";
import EditModule from "../../../../layout/components/CRUD/Edit";
import EditAdminUserDetails from "./edit";
import AdminUserDetails from "../admin-users/details";

function Adminusers() {
  return (
    <Routes>
      <Route
        element={
          <>
            {/* <Header /> */}
            <Outlet />
          </>
        }
      >
        <Route path="/list" element={<List />} />
        <Route
          path="new-admin-user"
          element={
            <>
              <NewAdminuser />
            </>
          }
        />
        <Route
          path="new"
          element={
            <>
              <NewModule
                module={"admin_users"}
                modulePath="/admin/admin-users"
                primary_key="admin_user_id"
              />
            </>
          }
        />
        <Route
          path="edit/:primary_id"
          element={
            <>
              {/* <EditModule
                module={"admin_users"}
                modulePath="/admin/admin-users"
                primary_key="admin_user_id"
              /> */}

              <EditAdminUserDetails />
            </>
          }
        />
        <Route
          path="details/:primary_id"
          element={
            <>
              <AdminUserDetails />
            </>
          }
        />
        {/* <Route path="/summary" element={<Summary />} /> */}

        <Route index element={<Navigate to="/admin/admin-users/list" />} />
      </Route>
    </Routes>
  );
}

export default Adminusers;
