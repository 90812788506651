import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";

function CollectionSequenceDetails() {
  useEffect(() => {
    getCollectionSequenceDetails();
  }, []);
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);

  const [formData, setFormData] = useState({
    collection: '',
    sequence_value: '',
   
  });

  const getCollectionSequenceDetails = () => {
    var params = {};
    params = { collection_sequence_id: id };

    get("CollectionSequences/" +id, {})
      .then((data: any) => {
        console.log(data);
        let collectionSequenceData = data.data;
       
        setFormData(data.data);
        console.log(formData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(formData);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px" }}
    >
      <div className="row new-client-header">
        <div className="col-xs-12">
          <h5>Collection Sequence Details</h5>
        </div>
      </div>
      <div style={{ maxWidth: "1152px" }}>
        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Collection</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.collection}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Sequence Value</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.sequence_value}</span>
          </div>
        </div>

        
      </div>
    </div>
  );
}

export default CollectionSequenceDetails;
