import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "../../../../../utils/api";
import SnackbarAlert from "../../../../common/sanckbar";

function NewAdminUser() {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const [formData, setFormData] = useState({
    realm: "",
    username: "",
    password: "",
    email: "",
    emailVerified: "",
    user_id: 0,
    first_name: "",
    last_name: "",
    password_confirmation: "",
    user_role: "",
    status_text: "",
  });

  const handleNewAdminUser = async (e: any) => {
    e.preventDefault();
    console.log(e);

    let adminUserDetails = {
      realm: formData.realm,
      username: formData.username,
      password: formData.password,
      email: formData.email,
      emailVerified: formData.emailVerified,
      user_id: formData.user_id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      password_confirmation: formData.password_confirmation,
      user_role: formData.user_role,
      status_text: formData.status_text,
    };

    try {
      const response = await post("/AdminUsers", { data: adminUserDetails });
      console.log(response.data);
      if (response) {
        alert("Admin User added successfully");
        navigate("/admin/admin-users/list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />

      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>New Admin User</h5>
          </div>
        </div>
        <form onSubmit={handleNewAdminUser} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="userName">User Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="userName"
                  className="reg-input name-field"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  placeholder="User Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="email"> Email </label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="email"
                  className="reg-input name-field"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="email"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="firstName"
                  className="reg-input name-field"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="First Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="lastName">Last Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="lastName"
                  className="reg-input name-field"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="Last Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="userRole">User Role</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="userRole"
                  className="reg-input name-field"
                  name="user_role"
                  value={formData.user_role}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="User Role"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default NewAdminUser;
