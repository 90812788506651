import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";

import Rightbar from "../../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../../layout/components/Selectmenu/Selectmenu";
import Tabheader from "../../../../../layout/components/Tabheader/Tabheader";
import Table from "../../../../../layout/components/Table/Table";
import Tabledata from "../../../../../layout/components/Table/Table";
import Tabswitch from "../../../../../layout/components/Tabswitch/Tabswitch";
import { get } from "../../../../../utils/api";

import CreateInvoiceIcon from "../../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { Link } from "react-router-dom";
import ListModule from "../../../../../layout/components/CRUD/List/List";

function List() {



  return (
    <ListModule module="fy_settings" modulePath="/admin/settings" primary_id="fy_setting_id" />
   
  );
}

export default List;

