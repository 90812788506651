import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Outlet,
} from "react-router-dom";
import Navbar from "./layout/components/Navbar/Navbar";
import Sidebar from "./layout/components/Sidebar/Sidebar";
import MasterLayout from "./master-layout";

function App() {
  return (
    <>
      {/* <Navbar />
    <Sidebar></Sidebar>
    <Outlet /> */}
      {/* <MasterLayout></MasterLayout> */}
      <Outlet />
    </>
  );
}

export default App;
