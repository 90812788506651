import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Card, CardContent } from "@mui/material";
import { get, put } from "../../../utils/api";
import SnackbarAlert from "../../common/sanckbar";

function NewsCategoryDetails(props: any) {
  let { primary_id } = useParams();
  console.log("new_id", primary_id);
  useEffect(() => {
    getData();
  }, [primary_id]);

  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    news__category: "",
  });

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const getData = async () => {
    var params = {};
    params = { news_category_id: Number(primary_id) };
    console.log(params);

    await get(`news_category/details`, params)
      .then((res) => {
        console.log("res", res.data);
        setData(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(data);
    const newData = {
      news_category: data.news_category,
    };
    console.log("newData", newData);

    try {
      const response = await put(`news_category/${primary_id}`, newData);
      console.log(response);
      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/news_category/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  console.log("data.images_lng", data.images_lng?.pl);

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 d-flex justify-content-between align-items-center">
                <h5>News Category Details</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">News Category</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_category"
                      value={data.news_category}
                      onChange={handleChange}
                      disabled
                      className="textfield w-100"
                    />
                  </FormGroup>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default NewsCategoryDetails;
