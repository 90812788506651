import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { awsupload, getProfile, post, upload } from "../../../utils/api";
import imageCompression from "browser-image-compression";
import SnackbarAlert from "../../common/sanckbar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddProductCategory(props: any) {
  // const postData = () => {
  //   let params = {
  //     fromAddress: "no-reply@favhiker.com",
  //     toAddress: data.email_id,
  //     userName: data.first_name,
  //   };
  //   sendNotifyEmail(`send_email`, params)
  //     .then((res) => {
  //       if (res) {
  //         console.log("res", res.message);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    product_category: "",
    product: "",
    quantity: 0,
    image_url: "",
    image_id: "",
    status: "inactive",
    status_id: 0,
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const [termsAndPolicy, setTermsAndPolicy] = useState(false);

  // const isDisabled = () => {
  //   return (
  //     !data.first_name ||
  //     !data.last_name ||
  //     !data.email_id ||
  //     !data.phone_number ||
  //     !data.whatsapp_number ||
  //     !data.city ||
  //     !data.country ||
  //     !data.gender ||
  //     !data.age_confirmation ||
  //     !data.occupation ||
  //     !data.source ||
  //     !data.volunteer_confirmation ||
  //     !data.newsletter_confirmation ||
  //     !data.communication_mode ||
  //     !data.attendance_type ||
  //     !data.image_url
  //   );
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newData = { ...data };

    try {
      const response = await post("product_category", newData);
      console.log(response);
      setData(newData);
      console.log(newData);
      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/product_category/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
    console.log(data);
  };

  // const onChangeHandler = async (event: any) => {
  //   setLoading(true);
  //   console.log("converted------------file", event.target.files[0]);

  //   // data.image_url = event.target.files[0].name;
  //   // console.log(data.image_url)
  //   // setData(data);
  //   // setImageLoading(true);
  //   let bucketname = "tamil-sangam";
  //   let folder_name = "members";
  //   let dbname = "tamilsangam";

  //   const data1 = new FormData();
  //   const options = {
  //     maxSizeMB: 0.5,
  //     maxWidthOrHeight: 1920,
  //   };
  //   // for (const file of event.target.files) {
  //   const fileType = event.target.files[0].type.split("/")[1];
  //   const selectedFile = event.target.files[0];

  //   if (!selectedFile) {
  //     console.error("No file selected");
  //     return;
  //   }

  //   let compressedFile = await imageCompression(selectedFile, options);
  //   data1.append("files", compressedFile, selectedFile.name);
  //   data1.append("dbname", "tamilsangam");
  //   data1.append("folder_name", "members");
  //   data1.append("image_name", selectedFile.name);
  //   console.log("data");

  //   awsupload(
  //     "api/s3FileUploadPublicWithFileId/" +
  //       bucketname +
  //       "/" +
  //       folder_name +
  //       "/" +
  //       selectedFile.name +
  //       "/" +
  //       fileType,
  //     data1
  //   )
  //     .then((res: any) => {
  //       console.log(res);
  //       if (res) {
  //         console.log(res.image_id);
  //         // data.image_url = res.image_id;
  //         data.image_id = res.data.image_id;
  //         data.image_url = res.data.image_url;
  //         setData((prevData: any) => ({
  //           ...prevData,
  //           image_id: res.data.image_id,
  //           image_url: res.data.image_url,
  //         }));

  //         // data.image_url = selectedFile.name;
  //         // let new_data = { ...data }

  //         // const newImageData = {
  //         //   ...data.images_lng,
  //         //   [language]: res.data.image_url,
  //         // };
  //         // setData({
  //         //   ...data,
  //         //   image_id: res.data.image_id,
  //         //   image_url: res.data.image_url,
  //         //   images_lng: newImageData,
  //         // });
  //         // setData(data);

  //         // data.image_url = selectedFile.name;
  //         console.log(data.image_id);

  //         // setData(data);

  //         console.log("data", data);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.error(err);
  //     });
  // };

  const handleTerms = () => {
    setTermsAndPolicy(!termsAndPolicy);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h5>Add Product Category</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">
                      Product Category Name
                    </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="product_category"
                      value={data.product_category}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      // disabled={isDisabled()}
                      variant="contained"
                      sx={{
                        bgcolor: "#09414E",
                        "&:hover": {
                          backgroundColor: "#09414E",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AddProductCategory;
function amazonSESSendEmail(email: any, full_name: any) {
  throw new Error("Function not implemented.");
}
