import * as React from "react";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { TextField } from "@mui/material";
import "./index.scss";
import { RxCross2 } from "react-icons/rx";

export default function FilterChip(props: any) {
  return (
    <span className="filter-chip">
      <span className="filter-title"> {props.title.replace("_", " ")} </span>
      <span className="filter-value">
        {" "}
        {props.value}
        <RxCross2
          className="filter-close"
          onClick={() => {
            props.closeChip(props.index);
          }}
        />
      </span>
    </span>
  );
}
