import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import Rightbar from "../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../layout/components/Selectmenu/Selectmenu";
import Tabheader from "../../../../layout/components/Tabheader/Tabheader";
import Table from "../../../../layout/components/Table/Table";
import Tabledata from "../../../../layout/components/Table/Table";
import Tabswitch from "../../../../layout/components/Tabswitch/Tabswitch";
import { get } from "../../../../utils/api";

import CreateInvoiceIcon from "../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import List from "./List";
import Header from "./Header/index";
// import Summary from "./Summary/index";
import NewFieldSetting from "../field-settings/New/index";
import EditFieldSettingDetails from "./edit";
import FieldSettingDetails from "../field-settings/details";

function FieldSettings() {
 

  return (
    <Routes>
      <Route
        element={
          <>
            {/* <Header /> */}
            <Outlet />
          </>
        }
      >
        <Route path="/list" element={<List />} />
        <Route
          path="new-field-setting"
          element={
            <>
              <NewFieldSetting />
            </>
          }
        />
        <Route
        path="details/:id"
          element={
            <>
              <FieldSettingDetails />
            </>
          }
        />
        <Route
          path="edit-field-setting/:id"
          element={
            <>
              <EditFieldSettingDetails />
            </>
          }
        />
        {/* <Route path="/summary" element={<Summary />} /> */}

        <Route index element={<Navigate to="/admin/field-settings/list" />} />
      </Route>
    </Routes>
  );
}

export default FieldSettings;



