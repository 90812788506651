import React, { useState } from "react";

// Define the type for the component props
interface DescriptionComponentProps {
  description?: string; // Make description optional
}

const DescriptionComponent: React.FC<DescriptionComponentProps> = ({ description = "" }) => {
  // State to track whether the full description is shown
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  // Function to toggle description state
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  // Define the number of characters to show initially
  const maxChars = 40;

  // Show the shortened version if not expanded, otherwise show the full description
  const displayedText = isExpanded ? description : `${description.slice(0, maxChars)}...`;

  return (
    <div>
      <p>{displayedText}</p>
      {/* Only show the button if the description is longer than maxChars */}
      {description.length > maxChars && (
        <button
          onClick={toggleReadMore}
          style={{
            color: "blue",
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: "0",
            textDecoration: "underline",
          }}
        >
          {isExpanded ? "Show Less" : "Read More"}
        </button>
      )}
    </div>
  );
}


  export default DescriptionComponent;
