import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Table.scss";
import moment from "moment";
import {
  awsget,
  awsupload,
  awsuploadfile,
  get,
  patch,
  put,
} from "../../../utils/api";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // import delete icon
import Chip from "@mui/material/Chip";
import Pagination from "../Pagination/Pagination";
import { Link, useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TableAddNewIcon from "../../assets/images/Tabledata/add-new-btn.svg";
import TableEditIcon from "../../assets/images/Tabledata/edit-btn.svg";
import MaterialPagination from "../MaterialPagination/MaterialPagination";
import FieldSettingsIcon from "../../assets/images/Tabledata/field_settings_icon.svg";
import ColumnFieldSelect from "../ColumnFieldSelect";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, TableSortLabel } from "@mui/material";
import SnackbarAlert from "../../../modules/common/sanckbar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { DeleteForever } from "@mui/icons-material";
import DescriptionComponent from "../DescriptionComp/description";
import ConfirmationDialog from "../ConfirmationDialogBox/confirmation_box";

function Tabledata(props: any) {
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // getnewsImage('hh')
  }, []);

  console.log("props.table", props);

  let data = props.data;
  console.log("data----------", data);
  console.log("folderame", props.folderName);
  let dataKeys: any[] = [];
  if (props.fields && props.fields.length > 0) {
    props.fields.map((e: any) => {
      if (e.coloumn_status) {
        dataKeys.push(e.key);
      }
    });
    dataKeys.push("actions");
  } else if (props.columns) {
    dataKeys = props.columns;
  } else if (data[0]) {
    dataKeys = Object.keys(data[0]);
    console.log("data", data);
    console.log("dataKeys", dataKeys);
  }
  console.log("tojo", dataKeys);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const showRightSideBar = (rowData: any) => {
    // if (props.rightSidebar) {
    //   props.showRightSidebar({status:false,data:rowData});
    // } else {
    props.showRightSidebar({ status: true, data: rowData });
    // }
  };

  const setDetailViewModal = (rowData: any) => {
    console.log({ status: true, id: rowData[props.primary_id] });
    props.setDetailViewModal({ status: true, id: rowData[props.primary_id] });
  };

  const handleInvoiceEdit = (e: any) => {
    props.handleInvoiceEdit(e);
  };

  const handleEdit = (e: any) => {
    props.handleEdit(e);
  };

  const getDirection = (key: string, sortBy: any) => {
    if (sortBy[key] == -1) {
      return "desc";
    } else {
      return "asc";
    }
  };
  const getSortKey = (sortBy: any) => {
    let sortByAr = Object.keys(sortBy);
    return sortByAr[0];
  };

  // const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
  //   const container = containerRef.current;
  //   if (container && e.deltaMode === 0) {
  //     container.scrollLeft += e.deltaY;
  //     e.preventDefault();
  //   }
  // };

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    const container = containerRef.current;
    if (container && e.deltaMode === 0) {
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const shouldPreventScroll =
        (container.scrollLeft === 0 && e.deltaY < 0) ||
        (container.scrollLeft === maxScrollLeft && e.deltaY > 0);
      if (shouldPreventScroll) {
        e.preventDefault();
      }
    }
  };

  const getnewsImage = (imageName: any) => {
    let folderName = props.foldername;
    let bucketname = "acceron-private";
    console.log("getDpImage", "called getprofile");
    awsget(
      "api/s3FileDownload/" + "tamil-sangam" + "/" + "news" + "/" + "dp.jpg",
      {}
    ).then((res: any) => {
      console.log(res);
      if (res && res.data != undefined) {
        return res.data.url;
      } else {
        return null;
      }
    });
  };

  let [documentFile, setDocumentfile] = useState<any>();
  let [documentname, setDocumentName] = useState("");

  const handleAddKeys = () => { };
  const handleCheckbox = (e: any) => {
    console.log(e[props.primary_id]);
    console.log(props);
    const checkedItems = [...props.checked];
    let index = checkedItems.indexOf(e[props.primary_id]);
    if (index !== -1) {
      checkedItems.splice(index, 1);
    } else {
      checkedItems.push(e[props.primary_id]);
    }
    props.setChecked(checkedItems);
    console.log("props.checked", checkedItems);
  };

  console.log("props.checked", props.checked);
  const navigate = useNavigate();

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const alert = (message: string, alert_severity: string) => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
  };

  const closeSnackbar = () => {
    // this.setState({ snackbar: false });
    setSnackbar(false);
  };

  let [document_url, setDocumentUrl] = useState<any>();
  let [document_id, setDocumentId] = useState<any>();
  let [how_to_use_doc_url, setHowToDocumentUrl] = useState<any>();
  let [how_to_use_doc_id, setHowToDocumentId] = useState<any>();

  const uploadFile = async (event: any, e: any) => {
    console.log("newProducts", e);
    // let file_id: any;
    let folder_name = "brochures";

    const data1 = new FormData();
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
    };

    const fileName = event.target.files[0].name
    let product_category_name = e.product_category + '_' + e.product;

    const uploading_file_name: any = product_category_name
      .split(".")[0]
      .replace(/[\s-]+/g, "_")
      .toLowerCase();
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    const itemBrochureName = uploading_file_name + "brochure." + fileType;
    const actualFileName = fileName

    console.log("fileName", itemBrochureName, event.target.files[0].name);
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let queryParams = {
      folder_name: folder_name,
      selectedFile: itemBrochureName,
      actual_file_name: actualFileName,
      file_type: fileType,
    };
    console.log(queryParams)
    data1.append("files", selectedFile, selectedFile.name);
    awsuploadfile("uploadDocument", data1, queryParams)
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);

          document_url = res.data.document_url;
          document_id = res.data.document_id;

          const extension = document_url.split(".").pop();

          if (document_url && document_id) {
            e["brochure_url"] = document_url;
            e["item_brochure"] = itemBrochureName;
            e["brochure_id"] = document_id;
            e["actual_brochure_url"] = actualFileName;
            UpdateData(e);
          }
          alert("File Uploaded Successfully", "success");
        }
      })
      .catch((err: any) => {
        console.error(err);
        alert("File Upload Failed", "error");
      });
  };

  const uploadHowToFile = async (event: any, e: any) => {
    // let file_id: any;
    let folder_name = "how_to_use";


    const data1 = new FormData();
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
    };

    const fileName = event.target.files[0].name
    let product_category_name = e.product_category + '_' + e.product;

    const uploading_file_name: any = product_category_name
      .split(".")[0]
      .replace(/[\s-]+/g, "_")
      .toLowerCase();
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    const product_how_to_use_name = uploading_file_name + "how_to_use." + fileType;
    const actualFileName = fileName

    console.log("fileName", product_how_to_use_name, event.target.files[0].name);
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let queryParams = {
      folder_name: folder_name,
      selectedFile: product_how_to_use_name,
      actual_file_name: actualFileName,
      file_type: fileType,
    }; // let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", selectedFile, selectedFile.name);

    awsuploadfile("uploadHowToDocument", data1, queryParams)
      .then((res: any) => {
        console.log("%%%uploadHowToDocument%%%", res);
        if (res.data) {
          console.log("inside if block");

          if (res.data.how_to_use_doc_url && res.data.how_to_use_doc_id) {
            console.log("Inside how_to_use_doc_url & how_to_use_doc_id check");
            e["how_to_use_doc_url"] = res.data.how_to_use_doc_url;
            e["actual_how_to_use_doc_url"] = actualFileName; // Verify the correct value here
            e["how_to_use_doc_id"] = res.data.how_to_use_doc_id;
            e["product_how_to_use_name"] = product_how_to_use_name;
            UpdateData(e);
            alert("File Uploaded Successfully", "success");
          } else {
            console.log("Missing how_to_use_doc_url or how_to_use_doc_id");
            alert("Missing document URL or ID", "error");
          }
        }
      })
      .catch((err: any) => {
        console.error("Error during upload:", err);
        alert("File Upload Failed", "error");
      });

  };

  const UpdateData = async (e: any) => {
    // e.preventDefault();
    let collection_name: any;
    let file_id: any;
    if (props.module === "cuisines") {
      console.log("newCuisines");
      collection_name = "cuisines";
      file_id = e.cuisine_id;
    } else if (props.module === "item_categories") {
      collection_name = "item_categories";
      file_id = e.item_category_id;
    } else if (props.module === "products") {
      console.log("newProducts");
      collection_name = "products";
      file_id = e.product_id;
    } else {
    }

    let data;

    try {
      let newData = {
        brochure_url: e.brochure_url,
        product_brochure_name: e.item_brochure,
        actual_brochure_url: e.actual_brochure_url,
        brochure_id: e.brochure_id,
        how_to_use_doc_url: e.how_to_use_doc_url,
        how_to_use_doc_id: e.how_to_use_doc_id,
        product_category_id: e.product_category_id,
        product_category: e.product_category,
        product_id: e.product_id,
        product: e.product,
        image_url: e.image_url,
        image_id: e.image_id,
        status: e.status,
        status_id: e.status_id,
        del: e.del,
        creation_date: e.creation_date,
        modification_date: e.modification_date,
        product_description: e.product_description,
        actual_how_to_use_doc_url: e.actual_how_to_use_doc_url,
        product_how_to_use_name: e.product_how_to_use_name
      };

      const response = await put(
        `${collection_name}/updateDocument/${file_id}`,
        newData
      );
      console.log("*************************************", response)
      props.getData();
      // if (response && response.message === 'success') {
      //   // props.getData();
      //   alert("Item Image uploaded successfully", "success");
      // }
    } catch (error) {
      console.log(error);
      // alert("Something went wrong", "warning");
    }
  };

  const handleCheckboxAll = () => {
    props.setIsHeaderChecked(!props.isHeaderChecked);
    if (props.isHeaderChecked) {
      props.checked.splice(0, props.checked.length);
    } else {
      data.map((e: any) => {
        props.checked.push(e[props.primary_id]);
      });
    }
    console.log("props.setChecked", props.isHeaderChecked, props.checked);
  };
  console.log("props.setChecked", props.isHeaderChecked, props.checked);


  // ***********************************************************************************************
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState({}); // State to hold the selected item
  const [selectedItemType, setSelectedItemType] = useState(null); // State to hold the selected item

  const handleDeleteClick = (e: any, type: any) => {
    setSelectedItem(e);
    setSelectedItemType(type);

    setOpenDialog(true);

  };

  // Function to close the dialog without deleting
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Function to handle deletion when "Yes" is clicked
  const handleConfirmDelete = async () => {
    console.log("Delete confirmed", selectedItem);
    console.log("Delete confirmed type", selectedItemType);

    try {
      const newData: any = {
        ...selectedItem,
      };
      if (selectedItemType === "brochure") {
        newData["brochure_url"] = "";
        newData["product_brochure_name"] = "";
        newData["brochure_id"] = "";
        newData["actual_brochure_url"]=""
      }else{
        newData["how_to_use_doc_url"] = "";
        newData["how_to_use_doc_id"] = "";
        newData["actual_how_to_use_doc_url"] = "";
        newData["product_how_to_use_name"] = "";

      }

      let primary_id: any = newData.product_id.toString();
      console.log("primary_id", newData, primary_id)
      const response = await put(`products/${primary_id}`, newData);
      console.log(response);
      if (response) {
        console.log(data);
        setSelectedItem({});

        setOpenDialog(false);
        props.getData();
      }
    } catch (error) {
      console.log(error);
    }

    // Execute your delete function here
    // Call your delete function, e.g., deleteItem(e.brochure_url);
  };



  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div
        className="row invoices-table-container"
        ref={containerRef}
        onWheel={handleWheel}
      >
        <div
          className="col-xs-12"
          style={{ paddingLeft: "0", paddingRight: "0" }}
        >
          <table className="table table-striped invoices-table">
            <thead className="thead-light thead-main">
              <tr>
                <>
                  <Checkbox
                    checked={props.isHeaderChecked}
                    className="d-flex"
                    {...label}
                    onChange={handleCheckboxAll}
                  />
                  {dataKeys.map((e: any) => {
                    return (
                      <th scope="col" className="table-headings">
                        <div>
                          <TableSortLabel
                            // active={orderBy === headCell.id}
                            active={e == getSortKey(props.sortBy)}
                            direction={getDirection(e, props.sortBy)}
                            // direction={orderBy === headCell.id ? order : "asc"}
                            onClick={(event) => props.handleSortBy(e)}
                          >
                            {/* {props.sortBy === 'asc' ? (
                            <ArrowUpwardIcon />
                          ) : (
                            <ArrowDownwardIcon />
                          )} */}
                            <span>
                              {e.includes("created_by_name")
                                ? e.replace("created_by_name", "Created By")
                                : e.includes("client_address")
                                  ? e.replace("client_address", "Address")
                                  : e.includes("del")
                                    ? e.replace("del", "Del")
                                    : e.includes("purchase_advance_amount")
                                      ? e.replace(
                                        "purchase_advance_amount",
                                        "Purchase Advance"
                                      )
                                      : e.includes("expense_group_name")
                                        ? e.replace(
                                          "expense_group_name",
                                          "Expense Group"
                                        )
                                        : e.includes("expense_group_id")
                                          ? e.replace("expense_group_id", "Bill Id")
                                          : e.includes("TO_From")
                                            ? e.replace("TO_From", "To/From")
                                            : e.replaceAll("_", " ").toLowerCase()}
                            </span>
                          </TableSortLabel>
                        </div>
                      </th>
                    );
                  })}
                  <th>
                    <ColumnFieldSelect
                      className="fields-dropdown"
                      onClick={props.onClick}
                      buttonClass={props.buttonClass}
                      imagePath={FieldSettingsIcon}
                      color={props.color}
                      handleFieldStatus={props.handleFieldStatus}
                      fields={props.fields}
                    // customElement={props.customElement}
                    />
                  </th>
                </>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length > 0 &&
                data.map((e: any) => {
                  return (
                    // <tr onClick={() => showRightSideBar(e)} key={e.id}>
                    <tr
                      // onClick={() => setDetailViewModal(e)}
                      key={e[props.primary_id]}
                    >
                      {/* <th scope="row" key={e.id}>
                      {e.client_id}
                    </th> */}
                      <td className="text-center">
                        {" "}
                        <Checkbox
                          className="form-check-input"
                          checked={props.checked.includes(e[props.primary_id])}
                          onChange={() => handleCheckbox(e)}
                          {...label}
                          sx={{
                            paddingLeft: "13px",
                          }}
                        />
                      </td>
                      {dataKeys.map((e2: any) => {
                        let value = e[e2];
                        if (Array.isArray(value) && value.length > 0) {
                          const keys = Object.keys(value[0]);
                          value = (
                            <table className="table table-striped invoices-table">
                              <thead>
                                <tr>
                                  {keys.map((key, i) => (
                                    <th key={i}>{key}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {value.map((row, i) => (
                                  <tr key={i}>
                                    {keys.map((key, j) => (
                                      <td key={j}>{row[key]}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          );
                          // } else if (e2.includes("images_lng")) {
                          //   const imageSrc = value.en
                          //     ? value.en
                          //     : value.pl
                          //     ? value.pl
                          //     : value.ta
                          //     ? value.ta
                          //     : null;

                          //   return imageSrc ? (
                          //     <img
                          //       src={imageSrc}
                          //       alt="Image"
                          //       className="news-details-image"
                          //     />
                          //   ) : null;
                        } else if (
                          e2.includes("album_name_lng") ||
                          e2.includes("title_lng") ||
                          e2.includes("content_lng")
                        ) {
                          const values = [];
                          if (value.en) {
                            values.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <span>English</span>
                                <p>{value.en}</p>
                              </div>
                            );
                          }
                          if (value.pl) {
                            values.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <span>Polish</span>
                                <p>{value.pl}</p>
                              </div>
                            );
                          }
                          if (value.ta) {
                            values.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <span>Tamil</span>
                                <p>{value.ta}</p>
                              </div>
                            );
                          }
                          return values.length > 0 ? (
                            <td className="news-table-images d-flex">
                              {values}
                            </td>
                          ) : (
                            <td></td>
                          );
                        } else if (e2.includes("images_lng")) {
                          const images = [];
                          if (value.en) {
                            images.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <span>English</span>
                                <img
                                  key="en"
                                  src={value.en}
                                  alt="Image"
                                  // className="news-details-image"
                                  height="60px"
                                  width="60px"
                                />
                              </div>
                            );
                          }
                          if (value.pl) {
                            images.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <span>Polish</span>
                                <img
                                  key="pl"
                                  src={value.pl}
                                  alt="Image"
                                  // className="news-details-image"
                                  height="60px"
                                  width="60px"
                                />
                              </div>
                            );
                          }
                          if (value.ta) {
                            images.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <span>Tamil</span>
                                <img
                                  key="ta"
                                  src={value.ta}
                                  alt="Image"
                                  // className="news-details-image"
                                  height="60px"
                                  width="60px"
                                />
                              </div>
                            );
                          }
                          return images.length > 0 ? (
                            <td className="news-table-images d-flex">
                              {images}
                            </td>
                          ) : (
                            <td></td>
                          );
                        } else if (e2.includes("thumbnail")) {
                          const images = [];
                          if (value.image_url) {
                            images.push(
                              <div className="d-flex flex-column align-items-center me-3">
                                <img
                                  key="image_url"
                                  src={value.image_url}
                                  alt="Image"
                                  // className="news-details-image"
                                  height="60px"
                                  width="60px"
                                />
                              </div>
                            );
                          }
                          return images.length > 0 ? (
                            <td className="news-table-images d-flex">
                              {images}
                            </td>
                          ) : (
                            <td></td>
                          );
                        } else if (e2.includes("content_lng")) {
                          const content = [];
                          if (value.en) {
                            content.push(
                              <div className="d-flex flex-column align-items-center me-2">
                                <span>
                                  <strong>English</strong>
                                </span>
                                <span className="content-title-ellipsis">{`${value.en.slice(
                                  0,
                                  15
                                )}...`}</span>
                              </div>
                            );
                          }
                          if (value.pl) {
                            content.push(
                              <div className="d-flex flex-column align-items-center me-2">
                                <span>
                                  <strong>Polish</strong>
                                </span>
                                <span className="content-title-ellipsis">{`${value.pl.slice(
                                  0,
                                  15
                                )}...`}</span>
                              </div>
                            );
                          }
                          if (value.ta) {
                            content.push(
                              <div className="d-flex flex-column align-items-center me-2">
                                <span>
                                  <strong>Tamil</strong>
                                </span>
                                <span className="content-title-ellipsis">{`${value.ta.slice(
                                  0,
                                  15
                                )}...`}</span>
                              </div>
                            );
                          }
                          return content.length > 0 ? (
                            <td scope="col">
                              <div className="news-details-images d-flex">
                                {content}
                              </div>
                            </td>
                          ) : null;
                        } else if (e2.includes("title_lng")) {
                          const title = [];
                          if (value.en) {
                            title.push(
                              <div className="d-flex flex-column align-items-center me-2">
                                <span>
                                  <strong>English</strong>
                                </span>
                                <span className="content-title-ellipsis">{`${value.en.slice(
                                  0,
                                  15
                                )}...`}</span>
                              </div>
                            );
                          }
                          if (value.pl) {
                            title.push(
                              <div className="d-flex flex-column align-items-center me-2">
                                <span>
                                  <strong>Polish</strong>
                                </span>
                                <span className="content-title-ellipsis">{`${value.pl.slice(
                                  0,
                                  15
                                )}...`}</span>
                              </div>
                            );
                          }
                          if (value.ta) {
                            title.push(
                              <div className="d-flex flex-column align-items-center me-2">
                                <span>
                                  <strong>Tamil</strong>
                                </span>
                                <span className="content-title-ellipsis">{`${value.ta.slice(
                                  0,
                                  15
                                )}...`}</span>
                              </div>
                            );
                          }
                          return title.length > 0 ? (
                            <td>
                              <div className="news-details-images d-flex">
                                {title}
                              </div>
                            </td>
                          ) : null;
                        } else if (e2.includes("title")) {
                          let title = [];
                          title.push(
                            <td className="content-title-ellipsis">{`${value.slice(
                              0,
                              30
                            )}...`}</td>
                          );
                          return title.length > 0 ? <>{title}</> : <td></td>;
                        } else if (e2.includes("content")) {
                          let content = [];
                          content.push(
                            <td className="content-title-ellipsis">{`${value.slice(
                              0,
                              30
                            )}...`}</td>
                          );
                          return content.length > 0 ? (
                            <>{content}</>
                          ) : (
                            <td></td>
                          );
                        } else if (e2.includes("images")) {
                          let images: any = [];
                          value.forEach((imageObj: any) => {
                            images.push(
                              <img
                                src={imageObj.s3Url}
                                // alt={imageObj.alt}
                                className="image"
                              />
                            );
                          });
                          return images.length > 0 ? <>{images}</> : <td></td>;
                        } else if (e2.includes("images")) {
                          let images: any = [];
                          value.forEach((imageObj: any) => {
                            images.push(
                              <img
                                src={imageObj.s3Url}
                                // alt={imageObj.alt}
                                className="image"
                              />
                            );
                          });
                          return images.length > 0 ? <>{images}</> : <td></td>;
                        } else if (e2.includes("product_description")) {
                          value = (
                            <div>
                              {e.product_description && (
                                <>
                                  <DescriptionComponent description={e.product_description} />
                                </>
                              )}
                            </div>
                          );
                        } else if (
                          e2.includes("brochure_url") &&
                          !e2.includes("how_to_use_doc_url")
                        ) {
                          value = (

                            <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>

                              <Link
                                to={e.brochure_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                {e.brochure_url && (
                                  <>
                                    {e.product_brochure_name} <br />
                                    {/* {e.actual_brochure_url} */}

                                  </>
                                )}
                              </Link>
                              {e.brochure_url && (
                                <IconButton
                                  onClick={() => handleDeleteClick(e, "brochure")}
                                  sx={{
                                    color: "red", // Change to red color
                                    minWidth: "0", // Remove minimum width
                                    padding: "5", // Remove padding
                                    fontSize: "small", // Set the font size to small
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  <DeleteIcon fontSize="small" /> {/* Smaller delete icon */}
                                </IconButton>
                              )}
                              <ConfirmationDialog
                                open={openDialog}
                                onClose={handleCloseDialog}
                                onConfirm={handleConfirmDelete}
                              />
                            </div>
                          );
                        } else if (
                          e2.includes("how_to_use_doc_url") &&
                          !e2.includes("brochure_url")
                        ) {
                          value = (
                            <div className="d-flex align-items-center" style={{ flexDirection: 'column' }}>
                              <Link
                                to={e.how_to_use_doc_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                              >
                                {e.how_to_use_doc_url && (
                                  <>
                                    {e.product_how_to_use_name} <br />
                                    {/* {e.actual_how_to_use_doc_url} */}
                                  </>
                                )}
                              </Link>

                              {e.how_to_use_doc_url && (
                                
                                <IconButton 
                                  onClick={() => handleDeleteClick(e, "how_to_use")}
                                  sx={{
                                    color: "red", // Change to red color
                                    minWidth: "0", // Remove minimum width
                                    padding: "5", // Remove padding
                                    fontSize: "small", // Set the font size to small,
                                    alignSelf: "flex-start",
                                  }}
                                >
                                  <DeleteIcon fontSize="small" /> {/* Smaller delete icon */}
                                </IconButton>
                              )}
                            </div>
                          );
                        } else if (e2.includes("date")) {
                          value = moment(value).format("DD-MMM-YYYY");
                        }
                        // else if (e2.includes("del")) {
                        //   value =
                        //     e.del === 0 ? (
                        //       <Chip
                        //         label="Active"
                        //         color="primary"
                        //         sx={{
                        //           height: "20px",
                        //           bgcolor: "rgba(135, 216, 106, 0.32)",
                        //           color: "#0A8442",
                        //         }}
                        //       />
                        //     ) : (
                        //       <Chip
                        //         label="Cancelled"
                        //         color="primary"
                        //         sx={{
                        //           height: "20px",
                        //           bgcolor: "rgba(216, 106, 106, 0.32)",
                        //           color: "#840A0A",
                        //         }}
                        //       />
                        //     );
                        // }
                        else if (e2 == "status") {
                          value =
                            e.status === "inactive" ? (
                              <Chip
                                label="Inactive"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(216, 106, 106, 0.32)",
                                  color: "#840A0A",
                                }}
                              />
                            ) : (
                              <Chip
                                label="Active"
                                color="primary"
                                sx={{
                                  height: "20px",
                                  bgcolor: "rgba(135, 216, 106, 0.32)",
                                  color: "#0A8442",
                                }}
                              />
                            );
                        } else if (e2.includes("actions")) {
                          value = (
                            <div className=" d-flex">
                              <button
                                className="btn"
                              // onClick={(e) => handleInvoiceEdit(e)}
                              >
                                <Link
                                  to={props.detailLink + e[props.primary_id]}
                                // onClick={(e) => handleInvoiceEdit(e)}
                                >
                                  <img src={TableAddNewIcon} alt="" />
                                </Link>
                              </button>
                              {props.module === "products" && (
                                <label className="btn file-upload-button me-3">
                                  <input
                                    className="input-document"
                                    accept="document/*"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                      uploadFile(event, e);
                                    }}
                                  />
                                  {/* <UploadIcon /> */}
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    UPLOAD PDF
                                  </span>
                                </label>
                              )}

                              {props.module === "products" && (
                                <label className="btn file-upload-button">
                                  <input
                                    className="input-document"
                                    accept="document/*"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                      uploadHowToFile(event, e);
                                    }}
                                  />
                                  {/* <UploadIcon /> */}
                                  <span
                                    style={{
                                      whiteSpace: "nowrap",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    UPLOAD HOW TO PDF
                                  </span>
                                </label>
                              )}
                              {props.module !== "general_enquiry" && (
                                <button className="btn">
                                  <Link
                                    to={props.editLink + e[props.primary_id]}
                                  // onClick={() => handleEdit(e)}
                                  >
                                    <img src={TableEditIcon} alt="" />
                                  </Link>
                                </button>
                              )}
                            </div>
                          );
                        } else if (e2.includes("invoice_field_settings")) {
                          value = <div></div>;
                        } else if (e2.includes("image_url")) {
                          return (
                            <td style={{ padding: "10px" }}>
                              <img height="60px" width="60px" src={value} />
                            </td>
                          );
                        }

                        return <td scope="col">{value}</td>;
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* <MaterialPagination
        count={props.totalCount}
        page={props.page}
        handleChangePage={props.handleChangePage}
        rowsPerPage={props.page}
        handleChangeRowsPerPage={props.handleChangeRowsPerPage}
      /> */}
      </div>
    </>
  );
}

export default Tabledata;
