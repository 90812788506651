import * as React from "react";
import { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownIcon from "../Dropdown/DropdownIcon";
import GridViewIcon from "@mui/icons-material/GridView";
import "./index.scss";

interface Props {
  name?: string;
  imagePath?: string;
  color?: string;
  className?: string;
  buttonClass?: string;
  endIcon?: any;
  spanclass?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  customIcon?: React.AllHTMLAttributes<HTMLDivElement>;
  customText?: string;
  customFunction?: any;
  menuItemClass?: string;
  menuDataItemClass?: string;
  dropDownData?: any;
  customElement?: any;
  handleFieldClick?: any;
  menuClass?: string;
}

const appsDropdownData = [
  {
    label: "",
  },
  // {
  //   label: "Recently Visited",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Favorites",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "All services",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Analytics",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Application Integration",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "AR & VR",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "AWS Cost Management",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Blockchain",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Business Applications",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Compute",
  //   icon: <MoreHorizIcon />,
  // },
  // {
  //   label: "Containers",
  //   icon: <MoreHorizIcon />,
  // },
];

export default function AppsDropdown(props: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDropdown = (e: any) => {
    const label = e.target.innerText;
    if (selectedItems.includes(label)) {
      setSelectedItems(selectedItems.filter((item) => item !== label));
    } else {
      setSelectedItems([label]);
    }
  };

  const filteredItems = appsDropdownData.filter((item) =>
    selectedItems.includes(item.label)
  );

  return (
    <div
      className={`${props.className} apps-dropdown-main`}
      onClick={props.onClick}
    >
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={props.endIcon}
        style={{ backgroundColor: props.color }}
        className={props.buttonClass}
      >
        {/* <img src={props.imagePath} alt="" /> */}
        <>
          <GridViewIcon sx={{ color: open ? "#af4518" : "" }} />
          <span
            className={
              open
                ? `${props.spanclass} apps-dropdown-active`
                : `${props.spanclass}`
            }
          >
            {props.name}
          </span>
        </>
      </Button>
      <Menu
        disableScrollLock
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={`${props.menuClass} apps-dropdown-menu`}
      >
        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-6 apps-dropdown-left">
            <div>
              {appsDropdownData?.map((item: any, index: number) => {
                if (index === 3) {
                  return (
                    <React.Fragment key={item.label}>
                      <hr />
                      <p onClick={(e) => handleDropdown(e)}>{item.label}</p>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <p key={item.label} onClick={(e) => handleDropdown(e)}>
                      {item.label}
                    </p>
                  );
                }
              })}
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-6 apps-dropdown-right">
            {filteredItems.map((item: any, index: number) => {
              return <p key={item.label}>{item.label}</p>;
            })}
          </div>
        </div>
        {/* <MenuItem className={props.menuItemClass} disableRipple>
          Custom field
        </MenuItem>
        <MenuItem className={props.menuItemClass} disableRipple>
          Field settings
        </MenuItem>
        <MenuItem className={props.menuItemClass} disableRipple>
          Custom field
        </MenuItem>
        <MenuItem className={props.menuItemClass} disableRipple>
          Field settings
        </MenuItem>
        <MenuItem className={props.menuItemClass} disableRipple>
          Custom field
        </MenuItem>
        <MenuItem className={props.menuItemClass} disableRipple>
          Field settings
        </MenuItem> */}
        {/* <MenuItem
          className={props.menuItemClass}
          onClick={handleClose}
          disableRipple
        >
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={props.customFunction} disableRipple>
          <>
            {props.customIcon}
            {props.customText}
          </>
        </MenuItem> */}
      </Menu>
    </div>
  );
}
