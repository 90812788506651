import React, { useEffect, useState } from "react";
import ListHeader from "../../../layout/components/CRUD/List/ListHeader";
import Table from "../../../layout/components/Table/Table";
import { get, post, put } from "../../../utils/api";
import DetailViewModal from "../../../layout/components/CRUD/List/DetailViewModal";
import { Box, Button, Modal, Typography } from "@mui/material";
import SnackbarAlert from "../../common/sanckbar";

function CareersList(props: any) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState<Array<any>>([]);
  const [id, setId] = useState(0);
  const [checked, setChecked] = useState<Array<any>>([]);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<any>({});
  const [invoiceDetails, setInvoiceDetails] = useState<any>({});
  const [invoiceSidebarStatus, setInvoiceSidebarStatus] = useState(false);
  const [financialYear, setFinancialYear] = useState<any>([]);
  const [sortBy, setSortBy] = useState<any>({ career_enquiry_id: -1 });
  // const [sortedData, setSortedData] = useState<{ invoice_no: number }[]>([]);

  const [columns, setColumns] = useState<Array<String>>([]);
  const [columnFields, setColumnFields] = useState<Array<any>>([]);
  const [filterData, setFilterData] = React.useState<any>([]);
  const [fieldsMaster, setFieldsMaster] = useState<Array<any>>([]);
  const [detailViewModal, setDetailViewModal] = useState({
    status: false,
    id: "",
  });

  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");

  const [folderName, setFoldername] = useState<string>("Careers");

  useEffect(() => {
    getCareerCategoryDetails();
    console.log("props", props);
  }, [page, rowsPerPage, filterData, sortBy]);

  const getCareerCategoryDetails = () => {
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
      sortBy: sortBy,
    };
    get(`career_enquiry`, params).then(async (response) => {
      await setData(response.data.data);
      console.log(response.data.data);
      await setTotalCount(response.data.totalCount);
      await setFieldsMaster(response.data.fields);
      console.log("tojo", response.data.fields);
    });
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const nextPage = newPage + 1;
    setPage(nextPage);
    console.log("workinghandle2", nextPage);
  };

  const handleUpdate = async (data: any) => {
    let newData = data;
    post(`career_enquiry/updateMultiple`, {
      ids: checked,
      updateData: newData,
    })
      .then((res) => {
        setChecked([]);
        setIsHeaderChecked(false);
        if (res) {
          alert(res.message ? res.message : "success", "success");
          getCareerCategoryDetails();
          console.log("res", res.message);
        }
      })
      .catch(function (error) {
        const errorMessage =
          error.response && error.response && error.response.message
            ? error.response.message
            : "An error occurred while submitting the form. Please try again.";
        alert(errorMessage, "warning");
        console.log(error);
      });
  };

  const handleDownload = () => {
    console.log("page", page);
    let params = {
      page: page,
      limit: rowsPerPage,
      filter: filterData,
      sortBy: sortBy,
    };

    get(`career_enquiry/pathToYourDownload`, params)
      .then((res) => {
        const encoder = new TextEncoder();
        const encodedData = encoder.encode(res.data);
        const decoder = new TextDecoder("utf-8");
        const decodedData = decoder.decode(encodedData);
        const blob = new Blob([decodedData], {
          type: "text/csv;charset=utf-8",
        });
        const url = URL.createObjectURL(blob);
        console.log("encodedData", url);
        console.log(res.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        link.click();
        setChecked([]);
        setIsHeaderChecked(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFinancialYear(event.target.value as number);
  };

  const showRightSidebar = (data: any) => {
    setInvoiceSidebarStatus(data.status);
    setSelectedInvoiceData(data.data);
    // getInvoiceDetails(data.data.invoice_no);
  };

  const handleFieldStatus = (cindex: any) => {
    const columnsFields = fieldsMaster.map((e: any, index: number) => {
      return {
        ...e,
        coloumn_status: cindex === index ? !e.coloumn_status : e.coloumn_status,
      };
    });
    setFieldsMaster(columnsFields);
  };

  const handleSortBy = async (key: string) => {
    if (sortBy[key] == 1) {
      let sortData = { [key]: -1 };
      await setSortBy(sortData);
    } else {
      let sortData = { [key]: 1 };
      await setSortBy(sortData);
    }
  };

  console.log(data.map((e: any) => e.primary_key));

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row pt-2 pb-2 mb-3">
        <div className="col-xs-12">
          <ListHeader
            totalCount={totalCount}
            fieldsMaster={fieldsMaster}
            module={"career_enquiry"}
            modulePath={"career_enquiry"}
            id={id}
            getData={getCareerCategoryDetails}
            filterData={filterData}
            setFilterData={setFilterData}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            financialYear={financialYear}
            handleChange={handleChange}
            handleUpdate={handleUpdate}
            handleDownload={handleDownload}
            moduleId={"career_enquiry_id"}
          />

          <Table
            data={data}
            folderName={folderName}
            detailLink={"/career_enquiry/details/"}
            editLink={"/career_enquiry/edit/"}
            module={"career_enquiry"}
            checked={checked}
            setChecked={setChecked}
            isHeaderChecked={isHeaderChecked}
            setIsHeaderChecked={setIsHeaderChecked}
            columns={columns}
            showRightSidebar={showRightSidebar}
            setDetailViewModal={setDetailViewModal}
            rightSidebarStatus={invoiceSidebarStatus}
            primary_id={"career_enquiry_id"}
            setId={setId}
            fields={fieldsMaster}
            handleFieldStatus={handleFieldStatus}
            sortBy={sortBy}
            handleSortBy={handleSortBy}
          />
        </div>
      </div>
    </>
  );
}

export default CareersList;
