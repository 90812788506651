import { FC, useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import App from "../App";
import Login from "../modules/login/login";
import Register from "../modules/register/register";
import { AppContext } from "../utils/state";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { getProfile } from "../utils/api";
import ForgotPassword from "../modules/login/forgot";

const AppRoutes: FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const { currentUser } = useAuth();
  let accessToken = "" + localStorage.getItem("accessToken");

  useEffect(() => {
    console.log("in private7777777777777777*******************************");

    // const fetchProfile = async () => {
    //   let data=await getProfile();
    //   console.log(data);
    //   state.user = data.user;
    //   state.isAuthenticated = true;
    //   dispatch({
    //     type: "setAuth",
    //     data: true,
    //   });
    // };
    // fetchProfile();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          {/* <Route path="/" element={<Login />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          {/* {state.isAuthenticated ? ( */}
          {accessToken.length > 10 ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              {/* <Route path='auth/*' element={<AuthPage />} /> */}
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
