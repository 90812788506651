import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { get, post, put } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { AdminUsersModel } from "./models/AdminUsersModel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { MenuItem, Select } from "@mui/material";
// import SnackbarAlert from "../../common/sanckbar";

type Props = {};

function EditAdminUserDetails() {
  let { primary_id } = useParams();
  // const { id } = useParams();
  const navigate = useNavigate();
  // console.log(id);
  // })
  const [formData, setFormData] = useState({
    // realm: "",
    // username: "",
    password: "",
    email: "",
    // emailVerified: "",
    // user_id: 0,
    first_name: "",
    last_name: "",
    // password_confirmation: "",
    role: "",
    status: "",
    status_id: 0,
  });

  useEffect(() => {
    getAdminUserDetails();
  }, []);
  const getAdminUserDetails = async () => {
    var params = {};
    params = { admin_user_id: primary_id };

    await get(`admin_users/${primary_id}`, {})
      .then((data: any) => {
        // let adminUserData = data.data.data;
        // console.log(adminUserData);
        setFormData(data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(formData);

  // const handleNewAdminUser = async (e: any) => {
  //   e.preventDefault();
  //   console.log(e);

  //   let adminUserDetails = {
  //     realm: "realm",
  //     username: "username",
  //     password: "password",
  //     email: "email",
  //     emailVerified: "emailVerified",
  //     user_id: "user_id",
  //     first_name: "first_name",
  //     last_name: "last_name",
  //     password_confirmation: "password_confirmation",
  //     user_role: "user_role",
  //     status_text: "status_text",
  //   };
  //   console.log(adminUserDetails);

  //   try {
  //     var params = { admin_user_id: Number(primary_id), data: formData };

  //     post("AdminUsers/updateAdminUserDetails", params).then(function (data) {
  //       console.log(data);
  //       if (data) {
  //         navigate(`/admin/admin-users/details/${primary_id}`);
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleUpdateAdminUser = async (e: any) => {
    e.preventDefault();
    console.log(e);

    const newPassword = formData.password;
    console.log(newPassword);
    // const salt = bcrypt.genSaltSync(10);
    // const hashedPassword = bcrypt.hashSync(newPassword, salt);
    // console.log(hashedPassword);

    let adminUserDetails = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      password: newPassword,
      email: formData.email,
      role: formData.role,
      status: formData.status,
      status_id: formData.status_id,
    };
    console.log(adminUserDetails);

    try {
      var params = {
        admin_user_id: Number(primary_id),
        data: adminUserDetails,
      };

      put(`admin_users/${primary_id}`, adminUserDetails).then(function (data) {
        console.log(data);
        if (data) {
          navigate(`/admin/admin-users/list`);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div
        className="container"
        style={{ marginTop: "22px", paddingLeft: "20px" }}
      >
        <div className="row new-client-header">
          <div className="col-xs-12">
            <h5>Edit Admin User</h5>
          </div>
        </div>
        <form onSubmit={handleUpdateAdminUser} style={{ maxWidth: "1152px" }}>
          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="userName">User Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="userName"
                  className="reg-input name-field"
                  name="username"
                  value={formData.first_name}
                  onChange={handleChange}
                  placeholder="User Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="email"> Email </label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="email"
                  className="reg-input name-field"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="email"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="password"> Enter new password </label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="password"
                  className="reg-input name-field"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="password"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="firstName">First Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="firstName"
                  className="reg-input name-field"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="First Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="lastName">Last Name</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="lastName"
                  className="reg-input name-field"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="Last Name"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="lastName">Status</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                {/* <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="lastName"
                  className="reg-input name-field"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="Status"
                /> */}
                <Select
                  fullWidth
                  required
                  id="status"
                  className="reg-input name-field"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  placeholder="Status"
                >
                  <MenuItem value="inactive">Inactive</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                </Select>
              </FormGroup>
            </div>
          </div>

          <div className="row mt-3 mb-3">
            <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
              <label htmlFor="userRole">User Role</label>
            </div>
            <div className="col-xs-12 col-md-8 col-lg-8">
              <FormGroup>
                <TextField
                  sx={{ width: "100% !important" }}
                  fullWidth
                  required
                  id="userRole"
                  className="reg-input name-field"
                  name="user_role"
                  value={formData.role}
                  onChange={handleChange}
                  // error={Boolean(errorState.first_name)}
                  // helperText={errorState.first_name}
                  placeholder="User Role"
                ></TextField>
              </FormGroup>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              type="submit"
              className="d-flex justify-content-end"
              style={{ marginBottom: "50px" }}
            >
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
export default EditAdminUserDetails;
