import Tabswitch from "../../../../../layout/components/Tabswitch/Tabswitch";

function Header() {
  let tabData = [
    {
      tabName: "List",
      tabLink: "/admin/collection-sequences/list",
    },
    {
      tabName: "Summary",
      tabLink: "/admin/collection-sequences/summary",
    },
  ];

  return (
    <>
      <Tabswitch tabData={tabData} />
    </>
  );
}

export default Header;
