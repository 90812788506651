import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../../../utils/api";

function FieldSettingDetails() {
  useEffect(() => {
    getFieldSettingDetails();
  }, []);
  const { id } = useParams();
  const navigate = useNavigate();
  console.log(id);

  const [formData, setFormData] = useState({
    collection_name: "",
    fields: [],
  });

  const getFieldSettingDetails = () => {
    var params = {};
    // params = { field_settings_id: id };

    get("FieldSettings/" + id, params)
      .then((data: any) => {
        let fieldSettingData = data.data;
        console.log(fieldSettingData);
        setFormData(fieldSettingData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const generateTable = (value: any) => {
    if (Array.isArray(value) && value.length > 0) {
      const keys = Object.keys(value[0]);
      value = (
        <table className="table table-striped invoices-table">
          <thead>
            <tr>
              {keys.map((key, i) => (
                <th key={i}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value.map((row, i) => (
              <tr key={i}>
                {keys.map((key, j) => (
                  <td key={j}>{row[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return value;
  };

  console.log(formData);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px" }}
    >
      <div className="row new-client-header">
        <div className="col-xs-12">
          <h5>Field Setting Details</h5>
        </div>
      </div>
      <div style={{ maxWidth: "1152px" }}>
        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>Collection Name</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{formData.collection_name}</span>
          </div>
        </div>

        <div className="row mt-3 mb-3">
          <div className="col-xs-12 col-md-4 col-lg-4 d-flex align-items-center">
            <span>fields</span>
          </div>
          <div className="col-xs-12 col-md-8 col-lg-8">
            <span>{generateTable(formData.fields)}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldSettingDetails;
