import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
// import Add from "./New";
import EditMembers from "./Edit";
// import ProductEnquiry from "./List";

import MembersDetails from "./Details";
import EditProductEnquiry from "./Edit";
import ProductEnquiryDetails from "./Details";
import ProductEnquiryList from "./List";
// import ProductEnquiryList from "./List";

function ProductEnquiry() {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path="list"
          element={
            <>
              <ProductEnquiryList />
            </>
          }
        />
        {/* <Route
          path="new"
          element={
            <>
              <Add />
            </>
          }
        /> */}
        <Route
          path="edit/:primary_id"
          element={
            <>
              <EditProductEnquiry />
            </>
          }
        />
        <Route
          path="details/:primary_id"
          element={
            <>
              <ProductEnquiryDetails />
            </>
          }
        />

        <Route index element={<Navigate to="/product_enquiry/list" />} />
      </Route>
    </Routes>
  );
}

export default ProductEnquiry;
