import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { awsupload, getProfile, get, post, upload } from "../../../utils/api";
import imageCompression from "browser-image-compression";
import SnackbarAlert from "../../common/sanckbar";

function AddNews(props: any) {
  useEffect(() => {
    getNewsCategories();
  }, []);

  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    news_category_id: 0,
    news_category: "",
    news_title: "",
    news_content: "",
    news_url: "",
    image_url: "",
    image_id: "",
    status: "inactive",
    status_id: 0,
  });

  const [newsCategories, setNewsCategories] = useState<any>([]);
  const [selectedNewsCategory, setSelectedNewsCategory] = useState<any>({});
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  let [news_id, setNews_id] = useState(0);
  let [selectedLanguage, setSelectedLanguage] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);

  const getNewsCategories = () => {
    var params = {};
    get("news_category", params)
      .then((response: any) => {
        let newsData = response.data.data;
        console.log(newsData);
        setNewsCategories(newsData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = async (e: any) => {
    // handleCreateNews();
    e.preventDefault();
    console.log(data);
    const newData = { ...data };

    try {
      const response = await post("news", newData);
      console.log(response);
      setData(newData);
      console.log(newData);

      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/news/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };
  console.log("isTranslated", isTranslated);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleNewsCategory = (event: any) => {
    setSelectedNewsCategory(event.target.value);
    setData({
      ...data,
      news_category_id: event.target.value.news_category_id,
      news_category: event.target.value.news_category,
    });
  };

  const onChangeHandler = async (event: any) => {
    console.log("converted------------file", event.target.files[0]);
    // selectedLanguage = language;
    // setSelectedLanguage(selectedLanguage);
    setLoadingLogo(true);
    // if (selectedLanguage === "en") {
    //   setLoadingEn(true);
    // } else if (selectedLanguage === "pl") {
    //   setLoadingPl(true);
    // } else if (selectedLanguage === "ta") {
    //   setLoadingTa(true);
    // }
    // data.image_url = event.target.files[0].name;
    // console.log(data.image_url)
    // setData(data);
    // setImageLoading(true);
    let bucketname = "acceron-public";
    let folder_name = "news";
    let dbname = "acceron-dev";

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    // for (const file of event.target.files) {
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    data1.append("dbname", "acceron-dev");
    data1.append("folder_name", "news");
    data1.append("image_name", selectedFile.name);
    console.log("data");

    awsupload(
      "api/s3FileUploadPublicWithFileId/" +
        bucketname +
        "/" +
        folder_name +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res.image_id);
          // data.image_url = res.image_id;
          data.image_id = res.data.image_id;
          data.image_url = res.data.image_url;
          // data.image_url = selectedFile.name;
          // let new_data = { ...data }
          if (res.data.image_url) {
            console.log("selecetd", selectedLanguage, res.data.image_url);
            data.image_url = res.data.image_url;
            data.image_id = res.data.image_id;
            // if (selectedLanguage === "en") {
            //   data.images_lng.en = res.data.image_url;
            //   setLoadingEn(false);
            // } else if (selectedLanguage === "pl") {
            //   data.images_lng.pl = res.data.image_url;
            //   setLoadingPl(false);
            // } else if (selectedLanguage === "ta") {
            //   data.images_lng.ta = res.data.image_url;
            //   setLoadingTa(false);
            // } else {
            // }
          } else {
          }
          // const newImageData = {
          //   ...data.images_lng,
          //   [language]: res.data.image_url,
          // };
          // setData({
          //   ...data,
          //   image_id: res.data.image_id,
          //   image_url: res.data.image_url,
          //   images_lng: newImageData,
          // });
          setData(data);

          // data.image_url = selectedFile.name;
          console.log(data.image_id);

          // setData(data);

          console.log("data", data);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 d-flex justify-content-between align-items-center">
                <h5>Add News</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "5.85rem" }}
                  >
                    <p className="contact-label contact-label-news contact-label-product">
                      News category
                    </p>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        value={selectedNewsCategory}
                        // label="Apps"
                        onChange={handleNewsCategory}
                      >
                        {newsCategories.length > 0 &&
                          newsCategories.map((e: any) => (
                            <MenuItem
                              key={e.news_category_id}
                              value={e}
                              // onClick={(event) => handleFoodAppChange(e)}
                              disableRipple
                            >
                              {e.news_category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">News Title</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_title"
                      value={data.news_title}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline mb-3"
                    sx={{ gap: "6.3rem" }}
                  >
                    <span className="span-label-news">News Content</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_content"
                      value={data.news_content}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-baseline mb-3"
                    sx={{ gap: "8.16rem" }}
                  >
                    <span className="span-label-news">News URL</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_url"
                      value={data.news_url}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-between align-items-center news-button-section">
                    {/* <FormControl>
                      <InputLabel htmlFor="file-upload" shrink>
                        Upload an image
                      </InputLabel>
                      <Input
                        disabled={!data.news_content || !data.news_title}
                        id="file-upload"
                        type="file"
                        onChange={(e: any) => onChangeHandler(e)}
                        inputProps={{ accept: "image/*" }}
                      />
                      {loadingLogo ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        data.image_url !== "" && (
                          <div>
                            <img
                              src={data.image_url}
                              className="news-image-edit member-image-new mt-3"
                            />
                          </div>
                        )
                      )}
                    </FormControl> */}
                    <div></div>
                    <div className="d-flex justify-content-end">
                      <Button type="submit" size="small" variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AddNews;
