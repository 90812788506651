import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Chip from "@mui/material/Chip";
import "./Rightbar.scss";

type Anchor = "right";

export default function Rightbar(props: any) {
  let details = props.invoiceDetails;

  const Content = () => (
    <>
      {/* <Button onClick={props.showRightSidebar(false)}>{anchor}</Button> */}
      <List className="rightbar-header">{props.headingContent}</List>
      <Divider />
      <List className="rightbar-tab">{props.tabContent}</List>
      <Divider />
      <List className="rightbar-body">{props.bodyContent}</List>
      <Divider />
      <List className="rightbar-sub-body">{props.bodySubContent}</List>
      <Divider />
      <List className="rightbar-footer">{props.footerContent}</List>
    </>
  );

  let sidebarWidth = "250px";
  if (props.sidebarWidth) {
    sidebarWidth = props.sidebarWidth;
  }
  return (
    <div className="rightbar-main">
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={props.status}
            onClose={(e) => props.showRightSidebar(false)}
            sx={{ width: sidebarWidth }}
          >
            <Content />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
