import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox } from "@mui/material";
import "./index.scss";
import { useNavigate } from "react-router-dom";

// interface Props {
//   name?: string;
//   imagePath?: string;
//   color?: string;
//   className?: string;
//   buttonClass?: string;
//   endIcon?: any;
//   spanclass?: string;
//   onClick?: React.MouseEventHandler<HTMLDivElement>;
//   customIcon?: React.AllHTMLAttributes<HTMLDivElement>;
//   customText?: string;
//   customFunction?: any;
//   menuItemClass?: string;
//   menuDataItemClass?: string;
//   dropDownData?: any;
//   customElement?: any;
//   handleFieldClick?: any;
//   menuClass?: string;
//   handleFieldStatus?: any;
// }

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    marginTop: theme.spacing(1),
    minWidth: "15.1041vw",

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      height: "2.2135vw",
      "& .MuiSvgIcon-root": {
        fontSize: 24,
        borderRadius: "0",
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
        "& path": {
          width: "24px !important",
          height: "24px !important",
        },
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ColumnFieldSelect(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  console.log(props.fields);

  return (
    <div className={props.className} onClick={props.onClick}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        className="manage-fields-button"
        onClick={handleClick}
        endIcon={props.endIcon}
        style={{ backgroundColor: props.color }}
        sx={{
          bgcolor: "#FFFFFF",
          minWidth: "24px",
          width: "24px",
          height: "24px",
          padding: "0",
          " & :hover": {
            backgroundColor: "#909090",
          },
          transition: "none",
        }}
      >
        <img src={props.imagePath} alt="" />
        <span className={props.spanclass}>{props.name}</span>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="manage-fields-menu"
      >
        {props.fields
          ?.filter((item: any) => item.key !== "images")
          .map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                onClick={props.handleFieldClick}
                disableRipple
                className="manage-fields-menu-item"
              >
                <Checkbox
                  sx={{ width: "24px", height: "24px" }}
                  checked={item.coloumn_status}
                  onClick={(e) => props.handleFieldStatus(index)}
                />
                <span className="capitalize">
                  {item.key.replaceAll("_", " ")}
                </span>
              </MenuItem>
            );
          })}
        <MenuItem
          sx={{ display: "flex", justifyContent: "center" }}
          className={props.menuItemClass}
          disableRipple
          onClick={() => navigate("/field-settings")}
        >
          Field settings
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
