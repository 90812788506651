import React, { useState } from "react";

interface Props {
  iconclass?: string;
  iconColor?: string;
}

const DropdownIcon = (props: Props) => {
  return (
    <div className={props.iconclass}>
      {props.iconColor === "white" ? (
        <img
          src={require("../../assets/images/Navbar/dropdown-arrow.svg").default}
          alt=""
        />
      ) : (
        <img
          src={
            require("../../assets/images/Tabheader/black-dropdown-arrow.svg")
              .default
          }
          alt=""
        />
      )}
    </div>
  );
};

export default DropdownIcon;
