import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { awsupload, get, patch, put } from "../../../utils/api";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import "./index.scss";
import DummyIcon from "../../../layout/assets/images/DummyImages/pexels-dummy.jpg";
import Tabswitch from "../../../layout/components/Tabswitch/Tabswitch";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import imageCompression from "browser-image-compression";
import AppLogoIcon from "../../../layout/assets/images/Navbar/app_logo_default.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ProductVarietyDetails() {
  let { primary_id } = useParams();
  useEffect(() => {
    getProductDetails();
    getProductCategories();
  }, [primary_id]);
  const navigate = useNavigate();
  console.log(primary_id);
  let products_id = primary_id;

  const [data, setData] = useState({
    // product_category_id: 0,
    product_category: "",
    product_variety: "",
    product: "",
    quantity: "",
    image_url: "",
    image_id: "",
    acceron_product_code: "",
    product_description: "",
    mrp: 0,
    // products_id: 0,
  });

  const [productCategories, setProductCategories] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = useState(198);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const [loadingLogo, setLoadingLogo] = useState(false);

  const inputHandler = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    console.log(e.target.value);
  };

  const handleSubmit = async () => {
    // const album_id = data.album_id;
    // if (data.images.length > 0) {
    console.log(data);
    const newData = {
      ...data,
    };
    console.log(newData);

    try {
      const response = await put(`product_variety/${primary_id}`, newData);
      console.log(response);
      if (response) {
        navigate("/product_variety/list");
        // getData();
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    }
    // }
  };

  const handleLogoUrlChange = (event: any, e: any) => {
    setData((prevData: any) => ({ ...prevData, image_url: e.target.value }));
    setLoadingLogo(false);
    uploadImage(event, e);
  };

  const handleProductCategory = (event: SelectChangeEvent) => {
    setData({ ...data, product_category: event.target.value as string });
  };

  const getProductCategories = () => {
    var params = {};
    get("product_category", params)
      .then((response: any) => {
        let productData = response.data.data;
        console.log(productData);
        setProductCategories(productData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadImage = async (event: any, e: any) => {
    // let file_id: any;
    let folder_name = "product_variety";
    setLoadingLogo(true);

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];
    // setImageName(selectedFile.name);
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }
    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    awsupload(
      "uploadFile/" +
        folder_name +
        "/" +
        products_id +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res);
          const newData = {
            ...data,
            image_url: res.data.image_url,
            image_id: res.data.image_id,
          };
          setData(newData);
          setLoadingLogo(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  console.log(data);

  const getProductDetails = () => {
    var params = {};
    params = { product_variety_id: Number(primary_id) };
    get("product_variety/details", params)
      .then((response: any) => {
        let productData = response.data;
        console.log(productData);
        setData(productData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log(data);
  return (
    <div
      className="container"
      style={{ marginTop: "22px", paddingLeft: "20px", marginBottom: "50px" }}
    >
      <Card>
        <CardContent>
          <div className="row">
            <div className="col-xs-12">
              {/* <div className="d-flex justify-content-between align-items-center fla-modal-header">
                <p className="mb-0">View application</p>
                <Button onClick={handleModalClose}>close</Button>
              </div> */}
              <div className="d-flex justify-content-between align-items-start fla-modal-content">
                <div className="w-100">
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">
                      Product category
                    </p>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // size="small"
                        value={data.product_category}
                        // label="Apps"
                        onChange={handleProductCategory}
                        disabled
                      >
                        {productCategories.length > 0 &&
                          productCategories.map((e: any) => (
                            <MenuItem
                              key={e.product_category_id}
                              value={e.product_category}
                              // onClick={(event) => handleFoodAppChange(e)}
                              disableRipple
                            >
                              {e.product_category}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">
                      Product Variety
                    </p>
                    <TextField
                      className="contact-field w-100"
                      //   id="outlined-basic"
                      type="text"
                      name="product_variety"
                      value={data.product_variety}
                      onChange={(e) => inputHandler(e)}
                      disabled
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Title"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">
                      Product
                    </p>
                    <TextField
                      className="contact-field w-100"
                      //   id="outlined-basic"
                      type="text"
                      name="product"
                      value={data.product}
                      onChange={(e) => inputHandler(e)}
                      disabled
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Title"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">
                      Quantity
                    </p>
                    <TextField
                      className="contact-field w-100"
                      //   id="outlined-basic"
                      type="text"
                      name="quantity"
                      value={data.quantity}
                      onChange={(e) => inputHandler(e)}
                      disabled
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Title"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">
                      Acceron Product Code
                    </p>
                    <TextField
                      className="contact-field w-100"
                      //   id="outlined-basic"
                      type="text"
                      name="acceron_product_code"
                      value={data.acceron_product_code}
                      onChange={(e) => inputHandler(e)}
                      disabled
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Acceron Product Code"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">
                      Product Description
                    </p>
                    <TextField
                      className="contact-field w-100"
                      //   id="outlined-basic"
                      type="text"
                      name="product_description"
                      value={data.product_description}
                      onChange={(e) => inputHandler(e)}
                      disabled
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Product Description"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <p className="contact-label contact-label-product">MRP</p>
                    <TextField
                      className="contact-field w-100"
                      //   id="outlined-basic"
                      type="text"
                      name="mrp"
                      value={data.mrp}
                      onChange={(e) => inputHandler(e)}
                      disabled
                      //   label="Project Title"
                      //   variant="outlined"
                      placeholder="Title"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#7CA5B0",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="w-50"></div>
                <div className="w-100">
                  <div>
                    <p className="contact-label contact-label-product app-logo-label">
                      Product Image
                    </p>
                    <div className="d-flex justify-content-start align-items-center app-logo-container">
                      {loadingLogo ? (
                        <div>
                          <CircularProgress />
                        </div>
                      ) : (
                        data.image_url !== "" && (
                          <div>
                            <img
                              src={data.image_url}
                              className="news-image-edit member-image-new mb-4"
                            />
                          </div>
                        )
                      )}
                      {/* <FormControl>
                        <Button
                          variant="outlined"
                          size="small"
                          component="label"
                          htmlFor="file-upload-cuisine"
                          className="app-logo-upload"
                          sx={{
                            color: "#FFFFFF",
                            bgcolor: "#09414E",
                            "&:hover": {
                              backgroundColor: "#09414E",
                            },
                          }}
                        >
                          Upload
                          <Input
                            id="file-upload-cuisine"
                            type="file"
                            onChange={(event) =>
                              handleLogoUrlChange(event, event)
                            }
                            inputProps={{ accept: "image/*" }}
                            className="image-input me-3"
                          />
                        </Button>
                      </FormControl> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center align-items-center">
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#09414E",
                    "&:hover": {
                      backgroundColor: "#09414E",
                    },
                  }}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </div> */}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default ProductVarietyDetails;
