import React, { useEffect, useState } from "react";
import { startCase } from "lodash";

import {
  Box,
  Button,
  FormControl,
  FormGroup,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import AddNewButton from "../../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import Filter from "../../../Filter";
import { get } from "../../../../../utils/api";
import MasterFilter from "../../../MasterFilter";
import MaterialPagination from "../../../MaterialPagination/MaterialPagination";
import FieldSettingsModal from "../FieldListSettingsModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ListHeader(props: any) {
  console.log(props);
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = React.useState(false);
  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleDeleteConfirm = () => {
    setIsDeleteConfirmed(true);
    props.handleUpdate({ del: 1 });
    handleCloseDelete();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openModal, openFieldsModal] = React.useState(false);
  const handleOpenModal = () => openFieldsModal(true);
  const handleCloseModal = () => openFieldsModal(false);

  // const options = props.searchFields.map((option: any) => ({
  //   label: option[props.key],
  //   value: option[props.key],
  // }));

  return (
    <div className="row invoicesheader-top">
      <div className="col-xs-12">
        <div className="d-md-flex d-block justify-content-between align-items-center tabheader-main">
          <div className="invoice-details">
            {/* <h3>Invoices Details</h3> */}
            <Box sx={{ minWidth: 120 }}>
              {/* <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  variant="standard"
                  // value={props.financialYear}
                  onChange={() => props.handleChange}
                >
                  {props.financialYear.map((item: any) => {
                    return (
                      <MenuItem value={item.financialYear}>
                        {item.financialYear}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl> */}
              {/* FY 2022-23 */}
            </Box>
          </div>
          <div className="invoice-master-filter">
            <FormGroup>
              <MasterFilter
                module={props.module}
                filterOptions={props.fieldsMaster}
                filterData={props.filterData}
                setFilterData={props.setFilterData}
                sx={{ width: "100%" }}
                placeholder={"Search " + startCase(props.module)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              ></MasterFilter>
            </FormGroup>
          </div>
          <div>
            {props.module !== "admin_users" &&
              props.module !== "product_enquiry" &&
              props.module !== "career_enquiry" &&
              props.module !== "general_enquiry" && (
                <Button
                  variant="outlined"
                  color="error"
                  className="invoice-create-btn"
                  onClick={() => {
                    // navigate("/news/new");
                    navigate("/" + props.modulePath + "/new");
                  }}
                >
                  {/* <img src={AddNewButton} alt="" /> */}
                  {props.module === "galleries" ? (
                    <span>Create Album</span>
                  ) : (
                    <span>Add {props.module}</span>
                  )}
                </Button>
              )}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <MaterialPagination
              count={props.totalCount}
              page={props.page}
              handleChangePage={props.handleChangePage}
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                className="invoice-action-btn"
              >
                Action
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleOpenDelete();
                    handleClose();
                  }}
                >
                  Delete
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleUpdate({ status_id: 1, status: "active" });
                  }}
                >
                  Activate
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleUpdate({ status_id: 0, status: "inactive" });
                  }}
                >
                  Deactivate
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.handleDownload();
                  }}
                >
                  Download
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                  Download invoice summary
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  Download detailed invoice
                </MenuItem>
                <MenuItem onClick={handleClose}>Cancel Invoice</MenuItem> */}
              </Menu>
            </div>

            <FieldSettingsModal
              modalType="invoiceList"
              handleOpenModal={handleOpenModal}
              openModal={openModal}
              module={props.module}
              handleCloseModal={handleCloseModal}
              getData={props.getData}
              fieldsMaster={props.fieldsMaster}
              page={props.page}
              rowsPerPage={props.rowsPerPage}
            />

            <div>
              <Modal
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Are you sure you want to delete this {props.module}?
                  </Typography>
                  <div className="d-flex justify-content-between align-items-center modal-btn-container mt-4">
                    <Button
                      variant="contained"
                      className="yes"
                      onClick={handleDeleteConfirm}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      className="no"
                      onClick={handleCloseDelete}
                    >
                      No
                    </Button>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
