import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { awsupload, get, getProfile, post, upload } from "../../../utils/api";
import imageCompression from "browser-image-compression";
import SnackbarAlert from "../../common/sanckbar";
import { set } from "lodash";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddProductVariety(props: any) {
  useEffect(() => {
    getProductCategories();
  }, []);

  // const postData = () => {
  //   let params = {
  //     fromAddress: "no-reply@favhiker.com",
  //     toAddress: data.email_id,
  //     userName: data.first_name,
  //   };
  //   sendNotifyEmail(`send_email`, params)
  //     .then((res) => {
  //       if (res) {
  //         console.log("res", res.message);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    product_category: "",
    product_variety: "",
    product: "",
    quantity: "",
    image_url: "",
    image_id: "",
    acceron_product_code: "",
    product_description: "",
    mrp: 0,
    status: "inactive",
    status_id: 0,
  });

  const [productCategories, setProductCategories] = useState<any>([]);
  const [selectedProductCategory, setSelectedProductCategory] =
    useState<any>(null);
  const [products, setProducts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const [termsAndPolicy, setTermsAndPolicy] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newData = { ...data };

    try {
      const response = await post("product_variety", newData);
      console.log(response);
      setData(newData);
      console.log(newData);
      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/product_variety/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);

  const handleProductSelect = (event: SelectChangeEvent) => {
    let product_obj: any = event.target.value;
    setSelectedProduct(product_obj);
    let product = product_obj.product;
    let product_id = product_obj.product_id;
    setData({
      ...data,
      product: product as string,
      product_id: product_id,
    });
  };

  const getProductCategories = () => {
    var params = {};
    get("product_category", params)
      .then((response: any) => {
        let productData = response.data.data;
        console.log(productData);
        setProductCategories(productData);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("products", products);
  const getProducts = (
    filter: any,
    page_no: number = 1,
    limit: number = 10
  ) => {
    get("products", {
      filter: filter,
      page: page_no,
      limit: limit,
    }).then((response) => {
      setProducts(response.data.data);
    });
  };

  const handleProducts = (event: SelectChangeEvent) => {
    console.log(event.target.value);
  };

  const handleProductCategory = (event: SelectChangeEvent) => {
    let product_category_obj: any = event.target.value;
    setSelectedProductCategory(product_category_obj);
    let product_category = product_category_obj.product_category;
    let product_category_id = product_category_obj.product_category_id;

    setData({
      ...data,
      product_category: product_category as string,
      product_category_id: product_category_id,
    });
    // setSelectedProductCategory(event.target.value as string);

    let filter = [
      {
        key: "product_category_id",
        value: product_category_id,
        type: "Number",
      },
    ];
    getProducts(filter, 1, 20);
  };

  console.log("selectedProductCategory", selectedProductCategory);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
    console.log(data);
  };

  // const onChangeHandler = async (event: any) => {
  //   setLoading(true);
  //   console.log("converted------------file", event.target.files[0]);

  //   // data.image_url = event.target.files[0].name;
  //   // console.log(data.image_url)
  //   // setData(data);
  //   // setImageLoading(true);
  //   let bucketname = "tamil-sangam";
  //   let folder_name = "members";
  //   let dbname = "tamilsangam";

  //   const data1 = new FormData();
  //   const options = {
  //     maxSizeMB: 0.5,
  //     maxWidthOrHeight: 1920,
  //   };
  //   // for (const file of event.target.files) {
  //   const fileType = event.target.files[0].type.split("/")[1];
  //   const selectedFile = event.target.files[0];

  //   if (!selectedFile) {
  //     console.error("No file selected");
  //     return;
  //   }

  //   let compressedFile = await imageCompression(selectedFile, options);
  //   data1.append("files", compressedFile, selectedFile.name);
  //   data1.append("dbname", "tamilsangam");
  //   data1.append("folder_name", "members");
  //   data1.append("image_name", selectedFile.name);
  //   console.log("data");

  //   awsupload(
  //     "api/s3FileUploadPublicWithFileId/" +
  //       bucketname +
  //       "/" +
  //       folder_name +
  //       "/" +
  //       selectedFile.name +
  //       "/" +
  //       fileType,
  //     data1
  //   )
  //     .then((res: any) => {
  //       console.log(res);
  //       if (res) {
  //         console.log(res.image_id);
  //         // data.image_url = res.image_id;
  //         data.image_id = res.data.image_id;
  //         data.image_url = res.data.image_url;
  //         setData((prevData: any) => ({
  //           ...prevData,
  //           image_id: res.data.image_id,
  //           image_url: res.data.image_url,
  //         }));

  //         // data.image_url = selectedFile.name;
  //         // let new_data = { ...data }

  //         // const newImageData = {
  //         //   ...data.images_lng,
  //         //   [language]: res.data.image_url,
  //         // };
  //         // setData({
  //         //   ...data,
  //         //   image_id: res.data.image_id,
  //         //   image_url: res.data.image_url,
  //         //   images_lng: newImageData,
  //         // });
  //         // setData(data);

  //         // data.image_url = selectedFile.name;
  //         console.log(data.image_id);

  //         // setData(data);

  //         console.log("data", data);
  //         setLoading(false);
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.error(err);
  //     });
  // };

  const handleTerms = () => {
    setTermsAndPolicy(!termsAndPolicy);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h5>Add Product Variety</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">Product Category</span>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // size="small"
                      value={selectedProductCategory}
                      // label="Apps"
                      onChange={handleProductCategory}
                      className="w-100"
                      size="small"
                    >
                      {productCategories.length > 0 &&
                        productCategories.map((e: any) => (
                          <MenuItem
                            key={e.product_category_id}
                            value={e}
                            // onClick={(event) => handleFoodAppChange(e)}
                            disableRipple
                          >
                            {e.product_category}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "12.7rem" }}
                  >
                    <span className="span-label-news">Product</span>
                    <Select
                      labelId="demo-simple-select-new-label"
                      id="demo-simple-select-new-label"
                      // size="small"
                      value={selectedProduct}
                      // label="Apps"
                      onChange={handleProductSelect}
                      className="w-100"
                      size="small"
                    >
                      {products.length > 0 &&
                        products.map((e: any) => (
                          <MenuItem
                            key={e.product_id}
                            value={e}
                            // onClick={(event) => handleFoodAppChange(e)}
                            disableRipple
                          >
                            {e.product}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "12.4rem" }}
                  >
                    <span className="span-label-news">Quantity</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="quantity"
                      value={data.quantity}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "6.05rem" }}
                  >
                    <span className="span-label-news">
                      Product Variety Name
                    </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="product_variety"
                      value={data.product_variety}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "11.05rem" }}
                  >
                    <span className="span-label-news">Description</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="product_description"
                      value={data.product_description}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "14.25rem" }}
                  >
                    <span className="span-label-news">MRP</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="mrp"
                      value={data.mrp}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      // disabled={isDisabled()}
                      variant="contained"
                      sx={{
                        bgcolor: "#09414E",
                        "&:hover": {
                          backgroundColor: "#09414E",
                        },
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AddProductVariety;
function amazonSESSendEmail(email: any, full_name: any) {
  throw new Error("Function not implemented.");
}
