import { Button, Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import Rightbar from "../../../../layout/components/Rightbar/Rightbar";
import Selectmenu from "../../../../layout/components/Selectmenu/Selectmenu";
import Tabheader from "../../../../layout/components/Tabheader/Tabheader";
import Table from "../../../../layout/components/Table/Table";
import Tabledata from "../../../../layout/components/Table/Table";
import Tabswitch from "../../../../layout/components/Tabswitch/Tabswitch";
import { get } from "../../../../utils/api";

import CreateInvoiceIcon from "../../../../layout/assets/images/Tabheader/create-invoice-add.svg";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import List from "./List";
import Header from "./Header/index";
// import Summary from "./Summary/index";
import NewFYSetting from "../settings/New/index";
import EditFYSettingDetails from "./edit";
import FYSettingDetails from "../settings/details";
import NewModule from "../../../../layout/components/CRUD/New";
import EditModule from "../../../../layout/components/CRUD/Edit";

function FYSettings() {
 

  return (
    <Routes>
      <Route
        element={
          <>
            <Header />
            <Outlet />
          </>
        }
      >
        <Route path="/list" element={<List />} />
        <Route
          path="new"
          element={
            <>
              <NewModule module={"fy_settings"} modulePath="/admin/settings" primary_key="fy_setting_id" />

            </>
          }
        />
        <Route
        path="details/:id"
          element={
            <>
              <FYSettingDetails />
            </>
          }
        />
         <Route
          path="edit/:id"
          element={
            <>
              <EditModule module={"fy_settings"} modulePath="/admin/settings" primary_key="fy_setting_id" />

            </>
          }
        />
        {/* <Route path="/summary" element={<Summary />} /> */}

        <Route index element={<Navigate to="/admin/settings/list" />} />
      </Route>
    </Routes>
  );
}

export default FYSettings;



