import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import "./index.scss";
import { Button, Card, CardContent } from "@mui/material";
import { post } from "../../../utils/api";
import SnackbarAlert from "../../common/sanckbar";

function AddNewsCategory(props: any) {
  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    news__category: "",
    status: "inactive",
    status_id: 0,
  });

  const [loadingLogo, setLoadingLogo] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  let [news_id, setNews_id] = useState(0);
  let [selectedLanguage, setSelectedLanguage] = useState("");
  const [isTranslated, setIsTranslated] = useState(false);

  const handleSubmit = async (e: any) => {
    // handleCreateNews();
    e.preventDefault();
    console.log(data);
    const newData = { ...data };

    try {
      const response = await post("news_category", newData);
      console.log(response);
      setData(newData);
      console.log(newData);

      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/news_category/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 d-flex justify-content-between align-items-center">
                <h5>Add News Category</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup
                    className="d-flex flex-row flex-nowrap justify-content-between align-items-center mb-3"
                    sx={{ gap: "8rem" }}
                  >
                    <span className="span-label-news">News Category</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="news_category"
                      value={data.news_category}
                      onChange={handleChange}
                      className="textfield w-100"
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-between align-items-center news-button-section">
                    <div></div>
                    <div className="d-flex justify-content-end">
                      <Button type="submit" size="small" variant="contained">
                        Save
                      </Button>
                    </div>
                  </div>
                  {/* )} */}
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AddNewsCategory;
