import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { awsupload, getProfile, post, upload } from "../../../utils/api";
import imageCompression from "browser-image-compression";
import SnackbarAlert from "../../common/sanckbar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddCareers(props: any) {
  // const postData = () => {
  //   let params = {
  //     fromAddress: "no-reply@favhiker.com",
  //     toAddress: data.email_id,
  //     userName: data.first_name,
  //   };
  //   sendNotifyEmail(`send_email`, params)
  //     .then((res) => {
  //       if (res) {
  //         console.log("res", res.message);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  const navigate = useNavigate();
  const [data, setData] = useState<any>({
    first_name: "",
    last_name: "",
    email_id: "",
    phone_number: 0,
    resume: "",
    status: "inactive",
    status_id: 0,
  });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [alert_severity, setAlertSeverity] = useState("warning");
  const [alert_message, setMessage] = useState("");
  const [termsAndPolicy, setTermsAndPolicy] = useState(false);

  const isDisabled = () => {
    return (
      !data.first_name ||
      !data.last_name ||
      !data.email_id ||
      !data.phone_number ||
      !data.resume
    );
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newData = { ...data };

    try {
      const response = await post("careers", newData);
      console.log(response);
      setData(newData);
      console.log(newData);
      if (response) {
        alert(response.message ? response.message : "success", "success");
        setTimeout(() => navigate("/careers/list"), 2000);
      }
    } catch (error: any) {
      const errorMessage =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "An error occurred while submitting the form. Please try again.";
      alert(errorMessage, "warning");
      console.log(error);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prevData: any) => ({ ...prevData, [name]: value }));
    console.log(data);
  };

  const onChangeHandler = async (event: any) => {
    setLoading(true);
    console.log("converted------------file", event.target.files[0]);

    // data.image_url = event.target.files[0].name;
    // console.log(data.image_url)
    // setData(data);
    // setImageLoading(true);
    let bucketname = "acceron-private";
    let folder_name = "careers";
    let dbname = "acceron-dev";

    const data1 = new FormData();
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
    };
    // for (const file of event.target.files) {
    const fileType = event.target.files[0].type.split("/")[1];
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    let compressedFile = await imageCompression(selectedFile, options);
    data1.append("files", compressedFile, selectedFile.name);
    data1.append("dbname", "tamilsangam");
    data1.append("folder_name", "careers");
    data1.append("image_name", selectedFile.name);
    console.log("data");

    awsupload(
      "api/s3FileUploadPublicWithFileId/" +
        bucketname +
        "/" +
        folder_name +
        "/" +
        selectedFile.name +
        "/" +
        fileType,
      data1
    )
      .then((res: any) => {
        console.log(res);
        if (res) {
          console.log(res.image_id);
          // data.image_url = res.image_id;
          data.image_id = res.data.image_id;
          data.image_url = res.data.image_url;
          setData((prevData: any) => ({
            ...prevData,
            image_id: res.data.image_id,
            image_url: res.data.image_url,
          }));

          // data.image_url = selectedFile.name;
          // let new_data = { ...data }

          // const newImageData = {
          //   ...data.images_lng,
          //   [language]: res.data.image_url,
          // };
          // setData({
          //   ...data,
          //   image_id: res.data.image_id,
          //   image_url: res.data.image_url,
          //   images_lng: newImageData,
          // });
          // setData(data);

          // data.image_url = selectedFile.name;
          console.log(data.image_id);

          // setData(data);

          console.log("data", data);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const handleTerms = () => {
    setTermsAndPolicy(!termsAndPolicy);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const alert = (message: string, alert_severity: string = "warning") => {
    setSnackbar(true);
    setMessage(message);
    setAlertSeverity(alert_severity);
    console.log("snackbar", snackbar);
    console.log("message", message);
    console.log("alertSeverity", alert_severity);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  };

  return (
    <>
      <SnackbarAlert
        open={snackbar}
        closeSnackbar={closeSnackbar}
        message={alert_message}
        severity={alert_severity}
      />
      <div className="row">
        <div className="col-xs-12 news-container">
          <Card>
            <CardContent>
              <div className="col-xs-12 col-md-6 col-lg-6">
                <h5>Add Careers</h5>
              </div>
              <div className="col-xs-12 ">
                <form onSubmit={handleSubmit}>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>First Name</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="first_name"
                      value={data.first_name}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Last Name</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="last_name"
                      value={data.last_name}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Email Id</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="email"
                      name="email_id"
                      value={data.email_id}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Phone No.</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="phone_number"
                      value={data.phone_number}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Whatsapp No.</span>
                    <TextField
                      size="small"
                      variant="outlined"
                      name="whatsapp_number"
                      value={data.whatsapp_number}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>City </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="city"
                      value={data.city}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Country </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="country"
                      value={data.country}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Gender </span>
                    {/* <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    name="gender"
                    value={data.gender}
                    onChange={handleChange}
                    sx={{ minWidth: "1000px" }}
                  /> */}
                    <Select
                      defaultValue={"male"}
                      size="small"
                      name="gender"
                      value={data.gender}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      className="textfield"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                    </Select>
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Age Confirmation </span>
                    {/* <TextField
                    size="small"
                    variant="outlined"
                    type="Boolean"
                    name="age_confirmation"
                    value={data.age_confirmation}
                    onChange={handleChange}
                    sx={{ minWidth: "1000px" }}
                  /> */}

                    <Select
                      defaultValue={"yes"}
                      size="small"
                      name="age_confirmation"
                      value={data.age_confirmation}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      className="textfield"
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Occupation </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="occupation"
                      value={data.occupation}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Source </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="source"
                      value={data.source}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Volunteer Confirmation </span>
                    {/* <TextField
                    size="small"
                    variant="outlined"
                    type="Boolean"
                    name="volunteer_confirmation"
                    value={data.volunteer_confirmation}
                    onChange={handleChange}
                    sx={{ minWidth: "1000px" }}
                  /> */}
                    <Select
                      defaultValue={"yes"}
                      size="small"
                      name="volunteer_confirmation"
                      value={data.volunteer_confirmation}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      className="textfield"
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Newsletter Confirmation </span>
                    <Select
                      defaultValue={"yes"}
                      size="small"
                      name="newsletter_confirmation"
                      value={data.newsletter_confirmation}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      className="textfield"
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Communication Mode </span>
                    <TextField
                      size="small"
                      variant="outlined"
                      type="text"
                      name="communication_mode"
                      value={data.communication_mode}
                      onChange={handleChange}
                      className="textfield"
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-row justify-content-between align-items-center mb-3">
                    <span>Online Attendance </span>
                    {/* <TextField
                    size="small"
                    variant="outlined"
                    type="Boolean"
                    name="online_attendance"
                    value={data.online_attendance}
                    onChange={handleChange}
                    sx={{ minWidth: "1000px" }}
                  /> */}
                    <Select
                      defaultValue={"person"}
                      size="small"
                      name="attendance_type"
                      value={data.attendance_type}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "Without label" }}
                      className="textfield"
                    >
                      <MenuItem value="person">In Person</MenuItem>
                      <MenuItem value="online">Online</MenuItem>
                    </Select>
                  </FormGroup>
                  <div className="d-flex flex-row justify-content-between align-items-center mb-3 member-image-container">
                    <FormControl>
                      <InputLabel htmlFor="file-upload" shrink>
                        Upload Photo
                      </InputLabel>
                      <Input
                        id="file-upload"
                        type="file"
                        onChange={(e: any) => onChangeHandler(e)}
                        inputProps={{ accept: "image/*" }}
                      />
                    </FormControl>
                    {loading ? (
                      <div>
                        <CircularProgress />
                      </div>
                    ) : (
                      data.image_url !== "" && (
                        <div>
                          <img
                            src={data.image_url}
                            className="news-image-edit member-image-new mt-3"
                          />
                        </div>
                      )
                    )}
                  </div>

                  {/* <FormGroup className="d-flex flex-row justify-content-between align-items-baseline mb-3">
                  <span>Content</span>
                  <TextField
                    size="small"
                    variant="outlined"
                    type="text"
                    name="content"
                    value={data.content}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    sx={{ minWidth: "1000px" }}
                  />
                </FormGroup>
                <FormGroup className="d-flex flex-row justify-content-between align-items-baseline mb-3">
                  <span>Select Image</span>
                  <input
                    type="file"
                    accept="image/*"
                    name="imageUrl"
                    value={data.imageUrl}
                    onChange={handleChange}
                    style={{ minWidth: "1000px" }}
                  />
                </FormGroup> */}
                  {/* <div className="col-xs-12 d-flex justify-content-around align-items-center declaration-container">
                    <Checkbox
                      name="termsAndPolicy"
                      checked={termsAndPolicy}
                      onChange={handleTerms}
                    />
                    <p>
                      I have read and agree to the Terms of Service and Privacy
                      Policy of this site as well as the website’s use of
                      cookies
                    </p>
                    <Button onClick={handleOpen}>Read More</Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          In accordance with Article 6(1)(a) of the General Data
                          Protection Regulation of 27 April 2016. (Official
                          Journal of the EU L 119 of 04.05.2016) I consent to
                          the processing of my personal data for contact
                          purposes, i.e. to answer the question asked in the
                          contact form. In accordance with Article 13 of the
                          General Data Protection Regulation of 27 April 2016.
                          (Official Journal of the EU L 119 of 04.05.2016) we
                          inform that: 1) the administrator of your personal
                          data is ADVOX Ltd, ul. Blacharska 11, 61-006 Poznań;
                          2) contact with the Data Protection Inspector -
                          info@advox.pl; 3) your personal data will be processed
                          for contact purposes. According to Article 6(1)(a) of
                          the General Data Protection Regulation of 27 April
                          2016 and the Labour Code of 26 June 1974. ; 4) your
                          personal data will be stored for a period of 6 years;
                          5) you have the right to request from the
                          administrator access to personal data, the right to
                          rectify, delete or restrict processing, the right to
                          object to processing, the right to data portability,
                          the right to withdraw consent at any time; 6) you have
                          the right to lodge a complaint to the supervisory
                          authority; 7) the provision of personal data is
                          mandatory under the provisions of law and otherwise
                          voluntary. By submitting this form, I confirm that the
                          information provided is accurate and complete to the
                          best of my knowledge, and that I am interested in
                          becoming a member of this Association. I understand
                          that my application will be reviewed and that I may be
                          contacted for further information. use of cookies
                        </Typography>
                      </Box>
                    </Modal>
                  </div> */}
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      disabled={isDisabled()}
                      variant="contained"
                    >
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AddCareers;
function amazonSESSendEmail(email: any, full_name: any) {
  throw new Error("Function not implemented.");
}
